.nav_tab {
  display: flex;
  margin: px(0) 0 px(15) px(0);
  overflow: auto;

  [data-tab-trigger] {
    // margin: px(8) 0 0 px(8);
    padding: 0 px(2);
    white-space: nowrap;
    color: #999;
    font-size: px(14);
    transition: color 0.3s;

    + [data-tab-trigger] {
      margin-left: px(30);
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

[data-tab-trigger] {
  &.on {
    border-bottom: px(2) solid #222;
    color: #000;
    font-weight: 700;
  }
}

[data-tab-target] {
  display: none;

  &.showing {
    display: block;
    animation: tab-in 0.15s forwards;
  }

  &.shown {
    display: block;
  }

  &.hiding {
    display: block;
    animation: tab-in 0.15s reverse;
  }

  &.hidden {
    display: none;
  }
}

// tab show & hide
@keyframes tab-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
