@import 'font';
@import 'module';
@import 'animation';
@import 'tab';
@import 'promotion';
@import 'coupon';

/* main */
.ly_main {
  .wrap {
    border-radius: 0;
    background-color: transparent;
    overflow: visible;
  }

  .close {
    @include pos(absolute, $r: px(10), $t: px(-38));
    z-index: 1;

    i {
      @include bgf;
      width: px(38);
      height: px(38);
      @include svgfill(#fff, 'btn_main_close.svg');
    }
  }

  .main_switch {
    position: relative;

    .main_wrap {
      position: relative;
      background-color: transparent;
      min-height: px(550);

      @include mq('small') {
        min-height: px(500);
      }

      [class*='main_'] {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        border-top-right-radius: px(25);
        box-shadow: 0 px(-5) px(20) rgba(0, 0, 0, 0.2);
        min-height: px(550);

        @include mq('small') {
          min-height: px(500);
        }

        &.active {
          .tit_area {
            .tit {
              transition: transform 900ms, opacity 900ms;
              transform: translateY(0);
              opacity: 1;
            }
          }

          .form {
            transition: transform 900ms 200ms, opacity 900ms 400ms;
            transform: translateY(0);
            opacity: 1;
          }

          .btn_area_wide {
            transition: transform 900ms 400ms, opacity 900ms 600ms;
            transform: translateY(0);
            opacity: 1;
          }
        }

        .ip_line {
          height: px(40);

          input {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0 px(40) 0 0;
            height: px(40);
          }

          button.del {
            height: px(28);
          }
        }
      }

      .main_con02 {
        opacity: 0;
        visibility: hidden;
      }
    }

    .tab_wrap {
      z-index: 1;
      transition: height 500ms ease, background 500ms ease;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: px(75);
      border-top-right-radius: px(25);
      background: linear-gradient(left, #24c3b6 0%, #3782ce 100%);
      box-shadow: px(3) px(-4) px(6) rgba(0, 0, 0, 0.13);

      .tab02 {
        display: none;

        .under_tit {
          @include pos(absolute, $t: px(24), $l: px(25));
          transform: translateY(0);
        }

        .bg_point {
          right: auto;
          left: px(-16);
          top: px(-40);
          transform: translateY(0);
        }
      }

      .under_tit {
        @include pos(absolute, $t: 50%, $l: px(25));
        transform: translateY(-50%);

        @include mq('small') {
          left: px(20);
        }

        span {
          color: rgba(255, 255, 255, 0.9);
          font-size: px(18);
          font-weight: 300;
          white-space: nowrap;

          em {
            font-weight: 500;
            letter-spacing: px(-1.5);
          }
        }

        figure {
          display: inline-block;
          margin-left: px(10);
          animation: bounce-left 1s 1s forwards;
          width: px(70);
          height: px(15);
          background-image: url($urlImg + 'icon_arr.png');
          background-size: px(70) auto;

          @include mq('small') {
            display: none;
          }
        }
      }

      .bg_point {
        @include pos(absolute, $t: 50%, $r: px(-15));
        transform: translateY(-50%);
        opacity: 0.08;
        font-size: px(120);
        color: #fff;
        font-family: 'S-CoreDream';
        font-weight: 600;
      }
    }

    .main_wrap {
      .tit_area {
        margin-bottom: px(43);

        @include mq('small') {
          margin-bottom: px(25);
        }

        .img_area {
          @include pos(relative);

          [class*='img_'] {
            @include pos(absolute);
            will-change: border-radius;
            background-size: cover;

            &.img_people_01 {
              top: px(-13);
              left: px(211);
              z-index: 1;
              background-image: url($urlImg + 'main_img01.png');
              width: px(33);
              height: px(44);
            }

            &.img_people_02 {
              top: px(70);
              right: px(0);
              z-index: 1;
              background-image: url($urlImg + 'main_img02.png');
              width: px(38);
              height: px(52);
            }

            &.img_people_03 {
              top: px(0);
              left: px(0);
              z-index: 1;
              background-image: url($urlImg + 'main_img03.png');
              width: px(25);
              height: px(50);
            }

            &.img_people_04 {
              top: px(90);
              left: px(60);
              z-index: 1;
              background-image: url($urlImg + 'main_img04.png');
              width: px(30);
              height: px(54);
            }

            &.img_point_01 {
              @include pos(absolute, $t: px(-75), $l: px(-25));
              width: px(198);
              height: px(199);
              border-radius: 42% 40% 70% 40%/65% 25% 55% 35%;
              background-color: #fe4c3e;
              opacity: 0.1;
              animation: morph 8s linear infinite;
            }

            &.img_point_02 {
              @include pos(absolute, $t: px(-68), $l: px(176));
              width: px(71);
              height: px(67);
              border-radius: 42% 40% 70% 40%/65% 25% 55% 35%;
              background-color: #ff531e;
              opacity: 0.28;
              animation: morph 6s linear infinite;
            }

            &.img_point_03 {
              @include pos(absolute, $t: px(-99), $r: px(-10));
              width: px(189);
              height: px(230);
              border-radius: 80% 20% 70% 20%/70% 10% 40% 20%;
              background-color: #24c3b6;
              opacity: 0.1;
              animation: morph02 8s linear infinite;
            }

            &.img_point_04 {
              @include pos(absolute, $t: px(-100), $r: px(190));
              width: px(93);
              height: px(115);
              border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
              background-color: #3ee9fb;
              opacity: 0.2;
              animation: morph 6s linear infinite;
              animation-delay: 200ms;
            }
          }
        }

        .tit {
          opacity: 0;
          transform: translateY(-50px);
          display: block;
          font-size: px(32);
          line-height: 1.3;
          letter-spacing: -2px;
          font-weight: 200;

          em {
            font-weight: 700;
            letter-spacing: -2.5px;
          }

          i {
            @include pos(relative);
            font-weight: 700;
            letter-spacing: -3px;

            &:after {
              content: '';
              @include pos(absolute, $t: 0, $l: 50%);
              transform: translateX(-50%);
              display: inline-block;
              width: px(4);
              height: px(4);
              background-color: #222;
              @include bdr($o: true);
            }
          }
        }

        .sub_txt {
          margin-top: px(50);
          font-size: px(16);
          color: #666;
          font-weight: 300;
        }
      }

      .main_con01 {
        padding: px(40) px(35) px(0) px(35);

        @include mq('small') {
          padding: px(40) px(20) px(0) px(20);
        }
      }

      .main_con02 {
        min-height: auto !important;
        padding: px(40) px(35) px(55) px(35);

        @include mq('small') {
          padding: px(40) px(20) px(50) px(20);
        }

        .tit {
          text-align: right;
        }

        .ip_box {
          .txt {
            & ~ .del {
              right: px(20) !important;
            }
          }
        }
      }
    }

    &.active {
      .tab_wrap {
        height: 100%;
        background: linear-gradient(left, #ff6256 0%, #ec574b 100%);

        .tab01 {
          display: none;
        }

        .tab02 {
          display: block;
        }
      }

      .main_con01 {
        display: none;
      }

      .main_con02 {
        transition: visibility 500ms, opacity 500ms, height 500ms;
        visibility: visible;
        opacity: 1;
        z-index: 3;
      }
    }
  }

  .main_wrap {
    .form {
      & ~ .form {
        margin-top: px(20);
      }

      .hashtag {
        margin-top: px(8);
        font-size: 0;
        position: relative;
        white-space: nowrap;
        overflow-x: auto;

        button {
          display: inline-block;
          box-sizing: border-box;
          border: px(1) solid #cdcdcd;
          padding: px(3) px(9);
          font-size: px(12);
          color: #999;
          border-radius: px(12);

          & ~ button {
            margin-left: px(6);
          }
        }
      }
    }

    .btn_area_wide {
      padding: 0;
      margin-top: px(30);
      opacity: 0;
      transform: translateY(-50px);
    }

    .main_con02 {
      .tit_area {
        margin-bottom: px(45);

        @include mq('small') {
          margin-bottom: px(25);
        }
      }

      .form {
        & ~ .form {
          margin-top: px(30);

          @include mq('small') {
            margin-top: px(30);
          }
        }
      }

      .btn_area_wide {
        margin-top: px(50);

        @include mq('small') {
          margin-top: px(35);
        }
      }
    }
  }
}

.map_wrap {
  position: relative;
  width: 100%;
  height: 100%;

  .gnb_wrap {
    position: absolute !important;
  }

  #header {
    background-color: transparent;
  }

  .info_header_nav {
    display: none;
  }

  // 스크롤 특정 지점 도달했을 때
  &.header_mode {
    .fix.top {
      z-index: 20 !important;
    }

    .top_srch_wrap.type1 {
      .info_header {
        display: flex;
        align-items: center;
        background: #fff;
        box-shadow: 0 px(5) px(8) rgba(0, 0, 0, 0.08);

        .btn_home {
          background: url('../images/icon_arrow_down.svg') no-repeat 0 0/2.8rem;
        }

        &_default {
          padding: 0 px(0) 0 px(11);

          .btn_home + div {
            display: none;
          }
        }

        &_nav {
          display: block;
        }
      }

      .srch_box_wrap {
        display: none;
      }
    }
  }
}

.top_srch_wrap {
  position: relative;
  transition: 0.3s ease-out;

  &.hidden {
    transform: translateY(-120px);
  }

  &.active._hide {
    .view {
      display: none;
    }
  }

  &.active {
    animation: bgchange 0.15s both;

    .srch_box_wrap {
      padding-top: 0;
      border-bottom: px(1) solid #efefef;

      .btn_back {
        display: block;
        top: px(11);
      }

      .btn_allim {
        display: none;
        animation: pos_l 0.15s both;
      }

      .btn_my {
        display: none;
        animation: pos_r 0.15s both;
      }

      .btn_srch {
        display: none;
        right: px(55);
      }
    }

    .ip_box {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      padding-right: 0 !important;

      input {
        padding: 0 px(40) 0 px(34);
        font-size: px(16);
        color: #222;
        font-weight: 500;
      }

      .lbl {
        left: px(34);
        font-size: px(14);
        font-weight: 300;
        color: #999;
      }

      button.del {
        right: px(10);
      }
    }

    .filter_tab_swipe {
      margin-top: 0;

      .tab {
        border-bottom: px(1) solid #efefef;

        & > ul {
          padding: px(9) 0;

          & > li {
            padding-left: px(7);

            &:first-child {
              padding-left: px(0);
            }

            &.active {
              a,
              .btn {
                position: relative;
                border: 0;
                font-weight: 400;
                color: #fff !important;
                background-color: #2bbcb0;
              }
            }

            a,
            .btn {
              box-sizing: border-box;
              height: px(25);
              line-height: px(25);
              font-size: px(14);
              border-radius: px(12);
              color: #999;
              box-shadow: none;
              padding: 0 px(8);
            }
          }
        }
      }
    }

    .view {
      display: block;
    }

    .filter_save_wrap {
      display: block;
    }
  }

  &.focus {
    .filter_tab_swipe {
      .tab {
        & > ul {
          & > li {
            &.active {
              a,
              .btn {
                border: px(1) solid #47e1d4;
                background-color: #effefe;
                color: #2bbcb0 !important;
                font-weight: 500;
              }
            }

            a,
            .btn {
              &.change_txt {
                border: px(1) solid #47e1d4;
                background-color: #effefe;
                color: #2bbcb0 !important;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .srch_box_wrap.type_ios {
    .btn_my {
      display: none;
    }

    .btn_srch {
      right: px(30) !important;
    }

    .ip_box {
      input {
        padding-right: px(60) !important;
      }
    }
  }

  .srch_box_wrap,
  .form_wrap {
    padding: px(20) px(11) 0;

    [class^='btn_'] {
      @include pos(absolute, $t: px(30));
      font-size: 0;
      z-index: 2;

      i {
        @include bgf;
        width: px(28);
        height: px(28);
      }
    }

    .btn_allim {
      left: px(25);
      top: px(31);

      i {
        @include svgfill(#222, 'btn_allim.svg');
      }

      &.new {
        &:after {
          content: '';
          @include iconNew;
          top: px(0);
          right: px(2);
        }
      }
    }

    .btn_back {
      display: none;
      left: px(11);

      i {
        @include svgfill(#222, 'btn_back.svg');
      }
    }

    .btn_srch {
      right: px(61);

      i {
        @include svgfill(#222, 'btn_srch.svg');
      }
    }

    .btn_my {
      right: px(25);

      i {
        background: url(../images/btn_my.png) no-repeat 0 0;
        background-size: cover;
      }
    }
  }

  .ip_box {
    border: 0;
    border-radius: px(5);
    box-shadow: 0 px(3) px(6) rgba(0, 0, 0, 0.16);

    input {
      padding: 0 px(85) 0 px(58);
      height: px(50);
    }

    .lbl {
      left: px(58);
      font-size: px(14);
      font-weight: 300;
      color: #999;

      @include mq('small') {
        font-size: px(12);
      }
    }
  }

  .filter_tab_swipe {
    margin-top: px(12);
    position: relative;
    width: 100%;
    transform: translateZ(0);
    -webkit-transform: translate3d(0, 0, 0);

    .view {
      .item {
        padding: px(15) px(20);
        border-bottom: px(1) solid #efefef;
      }
    }

    .tab {
      position: relative;
      padding-left: px(15);
      padding-right: px(15);

      & > ul {
        position: relative;
        white-space: nowrap;
        font-size: 0;
        line-height: 0;

        & > li {
          display: inline-block;
          width: auto !important;
          padding-left: px(7);

          &:first-child {
            padding-left: px(0);
          }

          &.active {
            a,
            .btn {
              border: px(1) solid #47e1d4;
              background-color: #effefe;
              color: #2bbcb0 !important;
              font-weight: 500;
            }
          }

          a,
          .btn {
            position: relative;
            display: block;
            width: 100%;
            box-sizing: border-box;
            font-size: px(14);
            text-align: center;
            box-sizing: border-box;
            line-height: 1;
            font-size: px(14);
            border-radius: px(5);
            color: #222;
            box-shadow: 0 px(3) px(6) rgba(0, 0, 0, 0.16);
            border: px(1) solid #fff;
            background: #fff;
            padding: px(10) px(8);

            &.change_txt {
              color: #2bbcb0 !important;
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .filter_box_wrap {
    display: flex;
    flex-flow: wrap;

    .btn {
      position: relative;
      float: left;
      width: auto;
      padding: 0 px(20);
      margin-bottom: px(8);
      margin-right: px(8);
      height: px(38);
      border-radius: px(19);
      font-weight: 500 !important;
      font-size: px(14);
      color: #999;
      letter-spacing: -0.1rem;
      overflow: visible;
      line-height: 1;

      &:before {
        content: '';
        position: absolute;
        left: -1px;
        top: -1px;
        width: calc(100% + 1px);
        height: calc(100% + 1px);
        border: px(1) solid #efefef;
        overflow: hidden;
        border-radius: px(19);
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        position: relative;
        border-color: transparent;
        color: #2bbcb0;
        font-weight: 500;
        background-color: #effefe;

        &:after {
          content: '';
          position: absolute;
          left: -1px;
          top: -1px;
          width: calc(100% + 1px);
          height: calc(100% + 1px);
          border: px(1) solid #47e1d4;
          z-index: 1;
          overflow: hidden;
          border-radius: px(19);
        }
      }
    }
  }

  .filter_save_wrap {
    position: relative;
    display: none;
    background-color: #f8f8f8;
    box-shadow: 0 px(3) px(6) rgba(0, 0, 0, 0.16);

    .btn_list {
      position: relative;
      overflow: hidden;
      font-size: 0;
      padding-left: px(7);

      @include mq('small') {
        padding-left: 0;
      }

      & > li {
        display: inline-block;

        & ~ li {
          border-left: px(1) solid #fff;
        }
      }

      [class*='btn'] {
        position: relative;
        color: #999;
        font-size: px(14);
        min-height: px(22);
        padding: px(12) px(13);

        @include mq('small') {
          padding: px(12) px(10);
          font-size: px(13);
        }

        &.active {
          color: #222;
          font-weight: 400;
        }
      }
    }

    .btn_bookmark {
      &:before {
        content: '';
        @include bgf;
        background-image: url($urlImg + 'btn_bookmark.svg');
        width: px(18);
        height: px(17);
        margin-right: px(5);
        vertical-align: -3px;
      }

      &.active {
        &:before {
          background-image: url($urlImg + 'btn_bookmark_on.svg');
        }
      }
    }

    .btn_save {
      &:before {
        content: '';
        @include bgf;
        background-image: url($urlImg + 'btn_filter_save.svg');
        width: px(20);
        height: px(20);
        margin-right: px(5);
      }

      &.active {
        &:before {
          background-image: url($urlImg + 'btn_filter_save_on.svg');
        }
      }
    }

    .btn_reset {
      /*margin-left:px(5);*/
      &:before {
        content: '';
        @include bgf;
        background-image: url($urlImg + 'btn_filter_reset.svg');
        width: px(14);
        height: px(14);
        margin-right: px(5);
        vertical-align: -2px;
      }

      &.active {
        color: #999 !important;
        font-weight: 400 !important;
      }
    }

    .btn_close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: px(12);
      font-size: 0;
      width: px(28);
      height: px(28);
      @include bgf;
      background-image: url($urlImg + 'btn_filter_close.png');
    }
  }

  .view {
    display: none;

    .item {
      display: none;
    }
  }

  // 메인 개편
  &.type1 {
    .srch_box_wrap {
      display: flex;
      padding: px(10) px(15);

      .inner_wrap {
        position: relative;
        flex: 1;

        .ip_box {
          // .lbl {
          //     left: px(12);
          // }
          input {
            padding: 0 px(80) 0 px(12);

            &::placeholder {
              color: #999;
              font-size: px(14);
              font-weight: 300;
            }

            &:focus {
              &::placeholder {
                color: transparent;
              }
            }
          }
        }

        .btn_srch {
          top: px(10);
          right: px(48);
        }

        .btn_filter {
          top: px(10);
          right: px(12);

          i {
            background: url('../images/btn_filter.svg') no-repeat 0 0;
          }
        }
      }

      .recomm {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: px(50);
        height: px(50);
        margin-left: px(8);
        border-radius: px(5);
        background: rgba(34, 34, 34, 0.7);
        font-family: 'pretendard', 'Noto Sans KR';
        font-size: px(14);
        line-height: px(16);
        color: #fff;

        > strong {
          display: block;
        }
      }
    }

    .filter_check_wrap {
      display: none;
      padding: px(15) px(20);
      border-bottom: px(1) solid #efefef;
    }

    .filter_tab_swipe {
      display: none;

      .tab {
        > ul {
          > li {
            a {
              font-family: 'pretendard', 'Noto Sans KR';
              color: #222;
            }
          }
        }
      }
    }

    .filter_save_wrap {
      display: none;

      .btn_list {
        > li {
          ~ li {
            border-left-color: #efefef;
          }
        }
      }
    }

    &.active {
      .info_header {
        display: none;
      }

      .srch_box_wrap {
        padding: 0 px(15);

        .inner_wrap {
          .ip_box {
            .lbl {
              left: px(32);
            }

            input {
              padding: 0 px(85) 0 px(32);
            }
          }
        }

        .btn_filter {
          display: none;
        }

        .recomm {
          display: none;
        }
      }

      .filter_check_wrap {
        display: flex;
      }

      .filter_tab_swipe,
      .filter_save_wrap {
        display: block;
      }
    }
  }
}

.info_header {
  &_default {
    display: flex;
    justify-content: space-between;
    padding: px(11) px(15) 0;

    .side {
      display: flex;

      button {
        + button {
          margin-left: px(4);
        }
      }
    }
  }

  .btn_home,
  .btn_my,
  .btn_menu {
    position: relative;
    display: inline-block;
    width: px(28);
    height: px(28);
  }

  .btn_home {
    top: px(2);
    background: url('../images/btn_home.png') no-repeat 0 0/2.8rem;
    .text {
      font-family: 'pretendard';
      font-size: px(16);
      font-weight: 600;
      white-space: nowrap;
      padding-left: px(28);
    }
  }

  .btn_my {
    background: url('../images/btn_my.svg') no-repeat 0 0;
  }

  .btn_menu {
    background: url('../images/btn_menu.svg') no-repeat 50% 50%/1.6rem;
  }

  &_nav {
    position: relative;
    display: block;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: px(10);
      height: 100%;
      background-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0) 5%,
        rgb(255, 255, 255) 85%
      );
      z-index: 1;
    }

    &_scroll {
      display: flex;
      overflow-x: auto;
      padding: px(15) px(20) px(15) px(10);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .btn_round {
      flex: none;
    }
  }
}

.ly_apt_filter {
  .filter_box_wrap {
    display: flex;
    flex-flow: wrap;

    .btn {
      position: relative;
      flex: 0 33.3%;
      width: 33.3%;
      height: px(45);
      font-size: px(14);
      color: #999;
      letter-spacing: -0.1rem;
      overflow: visible;
      line-height: 1;
      border-radius: 0;
      margin: 0 !important;
      padding: 0 px(10);

      &.active {
        &:after {
          border-radius: 0;
        }
      }

      &:before {
        border-radius: 0;
      }
    }
  }
}

.srch_list_wrap {
  display: none;
  position: absolute;
  top: px(51);
  left: 0;
  right: 0;
  z-index: 1;

  &.active {
    display: block;
  }

  .inner_wrap {
    max-height: calc(100vh - 51px);
    background-color: transparent;
    box-shadow: 0 px(3) px(6) rgba(0, 0, 0, 0.16);
  }

  .search_list {
    background-color: #fff;
    padding: px(20);
    max-height: calc(100vh - 94px);
    min-height: 40vh;
    overflow-y: auto;

    .tit {
      display: block;
      font-size: px(14);
      color: #222;
      font-weight: 400;
      margin-bottom: px(10);
    }

    .noti {
      font-size: px(12);
      font-weight: 300;
      color: #999;
    }

    ul {
      &.recent {
        li {
          a {
            font-weight: 300;
          }
        }
      }

      li {
        position: relative;

        a {
          font-size: px(14);
          color: #999;

          .core {
            color: #15beb9;
          }
        }

        & ~ li {
          margin-top: px(5);
        }

        .btn_del {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: px(26);
          height: px(26);
          background: url($urlImg + 'btn_srch_del.png') center no-repeat;
          background-size: cover;
          font-size: 0;
        }
      }
    }
  }

  .btn_area {
    position: relative;
    padding: px(8) px(12);
    background-color: #f8f8f8;

    [class*='btn'] {
      position: relative;
      color: #999;
      font-size: px(12);
      font-weight: 300;
      min-height: px(28);

      &.active {
        color: #222;
        font-weight: 500;
      }
    }
  }
}

.fix.top {
  .filter_tab_swipe {
    .view {
      .item {
        padding-bottom: px(10);
      }
    }
  }

  .top_srch_wrap {
    .filter_box_wrap {
      @include clear;
      display: block;
      font-size: 0;
    }
  }
}

.map_con {
  @include pos(fixed, $t: 0, $r: 0, $b: 0, $l: 0);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-image: url(../images/temp/map.png);
  background-size: cover;

  &.type1 {
    height: calc(100vh - 14.3rem);
  }
}

#content {
  .filter_map_btn_wrap {
    z-index: 9;
    position: fixed;
    left: px(15);

    &.pos_l {
      top: px(148);
      width: px(40);
      padding: px(5) 0;
      border-radius: px(5);
      background-color: #fff;
      box-shadow: 0 px(3) px(6) rgba(0, 0, 0, 0.16);
      transition: 0.3s ease-out;

      &.hidden {
        transform: translateX(-60px);
      }
    }

    .btn {
      width: 100%;
      text-align: center;
      padding: px(8) 0;
      display: block;
      font-size: px(14);
      color: #222;

      & ~ .btn {
        border-top: px(1) solid #efefef;
      }

      &.active {
        color: #15beb9;
      }
    }
  }

  .btn_bookmark {
    position: fixed;
    left: px(15);
    top: px(308);
    display: block;
    z-index: 9;
    width: px(40);
    height: px(40);
    border-radius: px(5);
    background-color: #fff;
    box-shadow: 0 px(3) px(6) rgba(0, 0, 0, 0.16);
    font-size: 0;
    @include bgf;
    background-image: url($urlImg + 'icon_bookmark.png');
    background-position: center;
    background-size: px(18) px(17);
    transition: 0.3s ease-out;

    &.active {
      background-image: url($urlImg + 'icon_bookmark_on.png');
    }

    &.hidden {
      transform: translateX(-60px);
    }
  }

  .btn_location {
    position: fixed;
    left: px(15);
    bottom: px(180);
    display: block;
    z-index: 9;
    width: px(40);
    height: px(40);
    border-radius: px(5);
    background-color: #fff;
    box-shadow: 0 px(3) px(6) rgba(0, 0, 0, 0.16);
    font-size: 0;
    @include bgf;
    background-image: url($urlImg + 'icon_location.png');
    background-position: center;
    background-size: px(23) px(23);
    transition: 0.3s ease-out;

    &.hidden {
      transform: translateY(130px);
    }
  }

  .btn_app_info {
    z-index: 9;
    position: fixed;
    right: px(6);
    bottom: px(80);
    width: px(48);
    height: px(48);
    @include bgf;
    background-image: url($urlImg + 'icon_app_info.png');
    background-position: center;
    background-size: px(48) px(48);
    transition: 0.3s ease-out;

    &.hidden {
      transform: translateY(130px);
    }
  }
}

// 맵 마커
.map_info_wrap {
  box-sizing: border-box;
  position: absolute;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  min-width: px(170);
  padding: px(10) px(20);
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: px(5);
  z-index: 2;
  text-align: center;

  @include mq('small') {
    padding: px(10);
  }

  .txt {
    line-height: px(23);
    color: #fff;

    @include mq('small') {
      font-size: px(13);
    }
  }
}

[class*='map_marker'] {
  @include pos(absolute);
  border-bottom-left-radius: 0;
  white-space: nowrap;
  cursor: pointer;
  z-index: 1;

  &.active {
    z-index: 2;
    animation: bounce 1.5s infinite;

    .marker_info {
      display: block;
      z-index: 2;
    }

    .type_apt {
      &:after {
        content: '';
      }

      .marker_info {
        display: block;
        z-index: 2;
      }

      .marker_shadow {
        &:after {
          left: 0;
          bottom: px(-4.4);
          z-index: 2;
        }

        &:before {
          left: px(-0.55);
          bottom: px(-6.4);
          border-top-color: #36d9d6;
        }
      }
    }
  }

  &.map_marker_icon {
    box-shadow: 0px 0px 11px 3px rgba(34, 34, 34, 0.141);
    z-index: 0;

    &.active {
      animation: bounce 1.5s infinite;
      z-index: 1;
    }

    .marker_info {
      top: px(-22);
      left: 50%;
      transform: translateX(-50%);
      padding: 0 px(3);

      .name {
        width: auto;
        font-size: px(12);
        color: #222;
        font-weight: 500;
      }

      &.type01 {
        top: px(-24);
      }
    }

    &.my {
      box-shadow: none;

      .marker_icon {
        width: px(38);
        height: px(42);
        font-size: 0;
        background: url($urlImg + 'icon_my_location.png') center no-repeat;
        background-size: 100% auto;
        box-shadow: none;
      }
    }
  }

  .marker_info {
    display: none;
    max-width: px(160);
    @include pos(absolute, $t: px(-56), $l: 0);
    background-color: #fff;
    padding: px(9) px(10);
    color: #222;
    border: px(0.5) solid #222222;
    font-weight: 300;
    z-index: 2;
    letter-spacing: -0.5px;

    .name {
      max-width: px(160);
      @include elip(l1);
      font-size: px(12);
      color: #222;
      font-weight: 500;
    }

    .info {
      display: block;
      font-size: px(12);
      font-weight: 400;
      color: #999;

      p {
        display: inline-block;
      }
    }
  }

  .marker_inner {
    @include pos(relative);
    display: inline-block;
    min-width: px(80);
    padding: px(5) px(10) px(5) px(10);
    font-weight: 200;
    color: #fff;
    background: linear-gradient(180deg, #36d9d6 10%, #2acecb 100%);
    box-sizing: border-box;
    box-shadow: px(3) px(6) px(6) rgba(0, 0, 0, 0.23);

    &:after {
      content: '';
      @include pos(absolute, $t: 0, $l: 0);
      width: 100%;
      height: 100%;
      border: 0 solid #222;
    }

    &[class*='type_'] {
      &.type_schedule {
        padding: px(10.5) px(10);

        .day {
          color: #006e6c;
          font-weight: 400;
          margin-top: px(5);
        }
      }
    }

    &.type_subs {
      max-width: px(80);
      padding: px(5);
      background: linear-gradient(180deg, #3a7bfb 10%, #2f64cb);

      .data {
        text-align: center;

        .city {
          padding: px(1) px(4);
          background: #fff;
          border-radius: px(20);
          color: #222;
          font-family: 'pretendard', 'Noto Sans KR';
          font-weight: 400;
        }

        .day {
          overflow: hidden;
          text-overflow: ellipsis;
          color: #d6eaff;
          font-family: 'pretendard', 'Noto Sans KR';
          font-weight: 300;
        }
      }

      .marker_shadow {
        &:after {
          border-top-color: #3065ce;
        }
      }
    }

    .data {
      p {
        line-height: 1 !important;

        &.schedule {
          font-size: px(14);
          font-weight: 500;
        }

        &.price {
          @include elip($ty: l1);
          @include wfr;
          line-height: 1.1;
          font-size: px(17);
          padding-top: px(3);
          font-weight: 500;

          em {
            @include wfn;
            font-size: px(16);
          }
        }

        &.sqf {
          color: #006e6c;
          font-weight: 500;
          @include wfr;
          font-size: px(11);

          em {
            @include wfn;
            font-size: px(11);
          }
        }

        &.city {
          color: #006e6c;
          font-weight: 500;
          font-size: px(11);
        }

        &.day {
          @include wfr;
          font-size: px(11);
          opacity: 0.8;
          letter-spacing: 0;
          font-weight: 300;
          margin-top: px(-2);

          em {
            @include wfn;
            letter-spacing: px(-0.5);
          }
        }
      }
    }
  }

  .marker_icon {
    @include pos(relative);
    display: flex;
    align-items: center;
    width: px(24);
    height: px(23);
    background-color: #666;
    box-sizing: border-box;
    box-shadow: px(-4) px(6) px(5) rgba(0, 0, 0, 0.11);

    &.school {
      width: px(22);
      height: px(20);
      background-color: #18a24f;

      p {
        flex: 1;
        font-size: px(14);
        color: #fff;
        text-align: center;
      }

      &:before {
        content: '';
        display: flex;
        @include pos(absolute, $t: px(-10), $l: px(9));
        width: px(7);
        height: px(14);
        background-image: url($urlImg + 'ico_m_flag.png');
      }

      .marker_shadow {
        &:after {
          border-top-color: #18a24f;
        }
      }
    }

    [class*='ico'] {
      flex: 0 auto;
      margin: 0 auto;
      background-size: 100% auto;

      &.ico_subway {
        width: px(12);
        height: px(16);
        background-image: url($urlImg + 'ico_m_subway.png');
      }

      &.ico_mart {
        width: px(11);
        height: px(15);
        background-image: url($urlImg + 'ico_m_mart.png');
      }

      &.ico_hospital {
        width: px(13);
        height: px(13);
        background-image: url($urlImg + 'ico_m_hospital.png');
      }

      &.ico_park {
        width: px(16);
        height: px(12);
        background-image: url($urlImg + 'ico_m_park.png');
      }

      &.ico_park02 {
        width: px(13);
        height: px(14);
        background-image: url($urlImg + 'ico_m_park02.png');
      }

      &.ico_mart02 {
        width: px(13);
        height: px(14);
        background-image: url($urlImg + 'ico_m_mart02.png');
      }

      &.ico_mac {
        width: px(16);
        height: px(13);
        background-image: url($urlImg + 'ico_m_mac.png');
      }

      &.ico_coffee {
        width: px(16);
        height: px(12);
        background-image: url($urlImg + 'ico_m_coffee.png');
      }

      &.ico_etc {
        width: px(12);
        height: px(16);
        background-image: url($urlImg + 'ico_m_etc.png');
      }
    }

    .marker_shadow {
      &:after {
        content: '';
        @include pos(absolute, $l: px(-4), $b: px(0));
        @include iconSemo($w: px(8), $h: px(8), $ro: -90);
        clip: rect(0px, px(6), px(13), 0px);
        border-top-color: #666;
      }

      &:before {
        content: none;
      }
    }
  }

  .marker_shadow {
    &:after {
      content: '';
      @include pos(absolute, $l: px(-0.55), $b: px(-5.5));
      @include iconSemo($w: px(7), $h: px(13), $ro: -90);
      clip: rect(0px, px(10), px(11), 0px);
      border-top-color: #2acecb;
    }

    &:before {
      content: '';
      @include pos(absolute, $l: 0, $b: px(-5.5));
      @include iconSemo($w: px(7), $h: px(13), $ro: -90);
      clip: rect(0px, px(11), px(11), 0px);
    }
  }
}

#mw-sample02 {
  .sample_area {
    height: px(250) !important;
  }
}

// 메인_샘플
.ly_sample {
  .wrap {
    border-radius: 0 !important;
    overflow: visible !important;
  }

  .close {
    @include pos(absolute, $r: px(-5), $t: px(-38));
    z-index: 1;

    i {
      @include bgf;
      width: px(38);
      height: px(38);
      @include svgfill(#fff, 'btn_main_close.svg');
    }
  }

  .content {
    background-color: #efefef !important;
    padding: px(25) px(38) px(0);
    overflow: hidden;

    @include mq('small') {
      padding: px(25) px(25) px(0);
    }

    h4 {
      font-size: px(18);
      text-align: center;
      padding-bottom: px(10);
      margin: 0 px(1);
      border-bottom: 2px solid #222;
      letter-spacing: -0.75px;
      font-weight: 300;

      em {
        font-weight: 700;
      }

      s {
        @include wfr;
      }
    }

    .sample_area {
      @include pos(relative);
      height: 42vh;

      &:before {
        content: '';
        @include pos(absolute, $b: 0, $l: px(-38), $r: px(-38));
        height: 70%;
        background: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(239, 239, 239, 1)
        );
        z-index: 1;

        @include mq('small') {
          @include pos(absolute, $b: 0, $l: px(-25), $r: px(-25));
        }
      }

      [class*='table_apt'] {
        margin-top: px(15);
        width: 100%;

        &.table_apt_pct {
          position: absolute;
          top: 50%;
          transform: translateY(-56%);

          tbody {
            & ~ tbody {
              tr:first-child {
                th,
                td {
                  padding-top: px(20) !important;
                }
              }
            }
          }

          tr.point {
            td {
              font-size: px(17) !important;

              s {
                font-size: px(16);
              }
            }
          }

          tr {
            font-size: px(12);

            &:last-child {
              height: auto !important;

              th,
              td {
                padding-top: px(0) !important;
                padding-bottom: px(15) !important;
              }
            }

            th,
            td {
              padding: 0 !important;
            }

            td {
              font-family: 'roboto';
              font-weight: 500;
              font-size: px(14) !important;

              s {
                @include wfn;
              }
            }

            .graph {
              display: inline-block;
              width: 100%;
              height: px(5);
              vertical-align: bottom;
              @include bdr($v: 2.5);
              background-color: #fff;

              [class*='bar'] {
                height: 100%;
                @include bdr($v: 2.5);
                background: linear-gradient(to left, #5399d5, #63d8ce);

                &.bar_st01 {
                  width: 85%;
                }

                &.bar_st02 {
                  width: 100%;
                }

                &.bar_st03 {
                  width: 61%;
                }
              }
            }
          }
        }

        tbody {
          & ~ tbody {
            @include pos(relative);

            &:before {
              content: '';
              @include pos(absolute, $l: 0);
              display: inline-block;
              width: 100%;
              height: px(1);
              background: url($urlImg + 'bg_dash.png') left top repeat;
            }
          }

          tr {
            font-size: px(11);
            font-weight: 300;

            &.main {
              th {
                padding: px(8) 0 px(3);
                font-weight: 700;
                font-size: px(12);
                color: #222;
                white-space: nowrap;
              }

              td {
                padding: px(8) 0 px(3);
                font-size: px(14);
                color: #20cbbc;

                s {
                  color: #20cbbc;
                }
              }
            }

            &.point {
              th {
                padding: px(8) 0 px(3);
                font-weight: 700;
                font-size: px(12);
                color: #222;
                white-space: nowrap;
              }

              td {
                padding: px(8) 0 px(3);
                font-size: px(14);
                color: #20cbbc;

                s {
                  color: #20cbbc;
                }
              }
            }

            &:last-child {
              height: px(32);
              vertical-align: top;

              th,
              td {
                padding-top: px(3);
              }
            }

            th {
              color: #a7a7a7;
              text-align: left;
              font-weight: 300;

              s {
                &.name_01 {
                  margin-top: px(3);
                  font-size: px(14);
                }

                &.name_02 {
                  width: px(18);
                  height: px(8);
                  background: url($urlImg + 'bg_sample_01.png') center no-repeat;
                  background-size: px(18) auto;
                  margin-right: px(2);
                }

                &.name_03 {
                  width: px(33);
                  height: px(9);
                  background: url($urlImg + 'bg_sample_02.png') center no-repeat;
                  background-size: px(33) auto;
                  margin-right: px(2);
                  vertical-align: baseline;
                }

                @include wfn;
                font-weight: 700;
                font-size: px(12);
                display: inline-block;
                vertical-align: middle;
                margin-top: -3px;
              }
            }

            td {
              color: #666;
              text-align: right;
              font-weight: 500;
              // font-weight: 700;
              @include wfr;

              s {
                color: #666;
                @include wfn;
              }
            }
          }
        }
      }
    }
  }

  .sample_footer {
    position: relative;
    background-color: #fff;
    padding: px(12) px(38) px(30);

    @include mq('small') {
      padding: px(12) px(25) px(30);
    }

    &:before {
      content: '';
      @include pos(absolute, $b: px(-3), $l: 0);
      width: 100%;
      height: px(4);
      background-image: url($urlImg + 'dec_semo.png');
      background-position: 50%;
    }

    .noti {
      font-size: px(12);
      color: #999;
      text-align: center;
      line-height: 1.4;
      font-weight: 300;

      b {
        font-weight: 500;
        color: #222;
      }
    }

    .btn_area_wide {
      padding: 0;
      padding-top: px(12);

      .skip {
        overflow: hidden;
        border-radius: px(5);
        font-size: px(12);
        padding: px(12) 0;
        box-shadow: 0 px(4) px(10) rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
}

.ly_apt_list {
  .wrap {
    background-color: #f8f8f8;

    .content {
      background-color: #f8f8f8;
    }

    .ly_content {
      margin-top: px(10);
      min-height: calc(100% - 50px);
      background-color: #fff;

      .nodata_wrap {
        padding-top: px(180);
      }
    }
  }
}

.apt_list_wrap {
  position: relative;
  background-color: #fff;

  .item {
    position: relative;
    padding: px(20);
    border-bottom: px(1) solid #efefef;

    &.link {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: px(15);
        width: px(28);
        height: px(28);
        transform: translateY(-50%);
        @include bgf;
        background-image: url($urlImg + 'icon_arr02.png');
      }
    }

    &:first-child {
      padding-top: px(20);
    }

    &:last-child {
      border-bottom: 0;
    }

    .btn_del {
      position: absolute;
      top: px(20);
      right: px(14);
      width: px(28);
      height: px(28);
      background: url($urlImg + 'btn_list_del.png') center no-repeat;
      background-size: cover;
      font-size: 0;
    }

    .btn_bookmark {
      position: absolute;
      top: px(20);
      right: px(13);
      width: px(28);
      height: px(28);
      background: url($urlImg + 'icon_bookmark.png') center no-repeat;
      background-size: px(16) auto;
      font-size: 0;

      &.active {
        background-image: url($urlImg + 'icon_bookmark_on.png');
      }
    }

    .inner_wrap {
      display: block;

      .tit_wrap {
        strong {
          display: block;
          padding-right: px(110) !important;
          font-size: px(16);
          font-weight: 500;
          color: #222;
          padding-top: px(2);
          padding-right: px(28) !important;
          @include elip('l1');
        }

        span {
          display: block;
          padding-right: px(110);
          margin-top: px(2);
          font-size: px(12);
          color: #999;
          word-break: keep-all;
        }
      }

      .price {
        margin-top: px(3);
        font-size: px(19);
        color: #15beb9;
        font-weight: 500;

        code {
          font-size: px(20);
        }
      }

      .info {
        margin-top: px(2);
        font-size: 0;

        li {
          display: inline-block;
          font-size: px(12);
          font-weight: 300;
          color: #666;

          & ~ li {
            &:before {
              content: '/';
              display: inline-block;
              margin: 0 px(2);
            }
          }
        }
      }

      p.info {
        font-size: px(12);
        font-weight: 300;
        color: #666;
      }
    }
  }
}

.list_count {
  display: flex;
  background-color: #fff;
  padding: px(25) px(20) 0;

  .tit {
    flex: 1;
    font-size: px(14);
    font-weight: 500;
  }

  .count {
    flex: 0 auto;
    font-size: px(13);
    color: #222;
    font-weight: 500;

    code {
      font-size: px(14);
      color: #15beb9;
    }
  }
}

.nodata_wrap {
  position: relative;
  padding: px(40) 0;
  text-align: center;

  .txt {
    font-size: px(14);
    color: #999;

    &:before {
      content: '';
      display: block;
      width: px(55);
      height: px(46);
      margin: 0 auto px(10);
      background: url($urlImg + 'icon_nodata.png') center no-repeat;
      background-size: cover;
    }
  }
}

[class*='ly_apt_'].full {
  &.ly_apt_detail {
    background-color: #15beb9;

    .bg_body_full {
      background-color: #15beb9;
      bottom: 50%;
    }

    .header {
      background-color: #15beb9;
      border-bottom: px(1) solid #15beb9;

      .btn_prev {
        i {
          @include svgfill(#fff, 'btn_prev_w.svg');
        }
      }

      .btn_location {
        i {
          @include svgfill(#fff, 'btn_location_w.svg');
        }
      }

      .btn_home {
        right: px(10);

        i {
          @include svgfill(#fff, 'btn_home.svg');
        }
      }

      .btn_my {
        right: px(10);

        i {
          @include svgfill(#fff, 'btn_my.svg');
        }
      }

      .tit {
        font-size: px(16);
        color: #fff;
        padding-right: px(80) !important;
        font-weight: 400 !important;
        transition: all 0.3s ease-out;
      }
    }
  }

  .header {
    background-color: #fff;
    border-bottom: px(1) solid #efefef;

    &.main {
      .tit {
        margin-left: px(20);
      }
    }

    .btn_prev {
      left: px(10);

      i {
        @include svgfill(#222, 'btn_prev_w.svg');
      }
    }

    .btn_home {
      right: px(10);

      i {
        @include svgfill(#222, 'btn_home.svg');
      }

      & ~ .btn_location {
        right: px(40) !important;
      }
    }

    .btn_my {
      right: px(20);

      i {
        @include svgfill(#222, 'btn_my.svg');
      }
    }

    .btn_location {
      right: px(10);

      i {
        @include svgfill(#222, 'btn_location_w.svg');
      }
    }

    .btn_srch {
      right: px(18);

      i {
        @include svgfill(#222, 'btn_srch.svg');
      }
    }

    .tit {
      width: 100%;
      display: block;
      text-align: left;
      margin-left: px(43);
      @include elip(l1);
      font-size: px(16);
      font-weight: 700;
      color: #222;

      &.tac {
        margin-left: 0;
        text-align: center;
      }
    }
  }

  .nodata_wrap {
    background-color: #fff;
  }
}

[class*='ly_apt_'] {
  .form_wrap {
    position: relative;
    padding: 0;

    .ip_box {
      transition: border 100ms;
      border: 0;

      input {
        padding-left: px(15) !important;
        padding-right: px(80) !important;
        z-index: 1;
        position: relative;
      }

      .lbl {
        left: px(15) !important;
        font-weight: 400;
        font-size: px(14);
      }

      &:after {
        content: '';
        @include pos(absolute, $t: 0, $l: 0);
        width: 100%;
        height: 100%;
        border: px(1) solid #efefef;
        transition: border 100ms;
      }

      &.in {
        input {
          @include pos(relative);
          z-index: 2;
        }
      }

      &.focus {
        &:after {
          content: '';
          border: px(2) solid $fcr;
        }
      }
    }

    .select_box {
      height: px(50);

      .lbl {
        line-height: px(50);
      }

      select {
        height: px(50);
        padding: 0 px(15);
        font-size: px(16);
        font-weight: 400;
        color: #222;
        padding-right: px(35);
      }

      &:after {
        content: '';
        @include pos(absolute, $t: 50%, $r: px(12));
        width: px(28);
        height: px(28);
        background-image: url($urlImg + 'bg_select02.png');
      }
    }

    .del {
      right: px(50) !important;
    }

    .txt {
      right: px(15);

      &.focus {
        right: px(40);
      }
    }

    .btn_srch {
      top: px(11);
      right: px(15);
    }

    .ip_del {
      &.normal {
        .del {
          right: px(15) !important;
        }
      }
    }
  }
}

.ly_apt_detail {
  .header {
    .tit {
      font-weight: 500 !important;
    }
  }

  section.tit_wrap {
    background-color: #15beb9;
    padding: px(20) px(20) px(40);
    text-align: center;
    box-shadow: 0 px(3) px(6) rgba(0, 0, 0, 0.16);

    .tit {
      display: inline-block;
      font-size: px(20);
      font-weight: 500;
      color: #fff;
      padding-left: px(30);
      margin-left: px(-30);
      background: url($urlImg + 'icon_bookmark02.png') left center no-repeat;
      background-size: px(30) auto;
      @include elip(l1);
      max-width: 80%;

      &.active {
        background-image: url($urlImg + 'icon_bookmark02_on.png');
      }
      
      &:disabled {
        padding-left: 0;
        margin-left: 0;
        background: none;
      }
    }

    .info_txt {
      display: block;
      margin-top: px(1);
      font-size: px(14);
      color: rgba(255, 255, 255, 0.8);
      word-break: keep-all;
    }

    .info_list {
      font-size: 0;
      margin-top: px(14);

      li {
        box-sizing: border-box;
        display: inline-block;
        font-size: px(12);
        color: #fff;
        border: px(1) solid #fff;
        border-radius: px(12);
        padding: px(3) px(10);

        & ~ li {
          margin-left: px(5);
        }
      }
    }
  }

  .acco_wrap {
    background-color: #fff;

    .item {
      position: relative;

      &._fix {
        .btn_acco {
          z-index: 7;
          position: fixed;

          &:after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }

    .btn_acco {
      transform: translate3d(0, 0, 0);
      box-sizing: border-box;
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      background-color: #f8f8f8;
      border-bottom: px(1) solid #fff;
      font-size: px(14);
      font-weight: 500;
      text-align: left;
      padding: px(11) px(20);
      z-index: 3;
      height: px(40);
      color: #222;

      &:after {
        display: none;
        content: '';
        position: absolute;
        top: 50%;
        right: px(14);
        transform: translateY(-50%) rotate(180deg);
        width: px(28);
        height: px(28);
        background: url($urlImg + 'btn_acco.png') center no-repeat;
        background-size: cover;
        transition: 0.3s ease 0s;
      }

      &.active {
        &:after {
          transform: translateY(-50%);
        }
      }
    }

    .acco_view {
      padding: px(65) px(20) px(25);
      transition: all 0.3s;

      .btn_wrap {
        display: flex;
        padding: 0 px(10);

        .btn {
          flex: 1;
          margin-right: px(4);
          border: px(1) solid #b9b9b9;
          border-radius: px(5);
          font-size: px(14);
          color: #999;
          padding: px(10) px(10);

          &:last-child {
            margin-right: 0;
            margin-left: px(4);
          }

          &:first-child {
            margin-left: 0;
          }

          &.point {
            background-color: #222;
            color: #fff;
            border-color: #222;
          }
        }
      }

      .map_sec {
        position: relative;
        height: px(200);
        overflow: hidden;
        margin: px(-25) px(-20) px(10);
      }
    }
  }

  .apt_d_info {
    .col_wrap {
      display: flex;
      align-items: center;

      * {
        line-height: 1;
      }

      .col {
        flex: 0 55%;

        & ~ .col {
          flex: 1;
        }

        .rdo_wrap {
          margin-top: px(6);
        }

        .btn {
          float: right;
          display: block;
          min-width: px(130);
          padding: px(10.5) px(10);
          border: px(1) solid #e8e8e8;
          font-size: px(14);
          color: #999;
        }

        .ip_box.focus:after {
          content: '';
          position: absolute;
          left: -1px;
          top: -1px;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          transition: border 100ms;
          border: 0.2rem solid #2bbcb0 !important;
        }
      }

      .tit_wrap {
        .sub_txt {
          display: block;
          font-size: px(12);
          color: #666;
        }

        .tit {
          display: block;
          margin-top: px(5);
          font-size: 0;

          span {
            font-size: px(24);
            line-height: 1.6;

            code {
              font-size: px(26);
            }
          }

          em {
            display: inline-block;
            vertical-align: px(5);
            margin-left: px(3);
            font-size: px(12);
            color: #2c64f3;
            font-weight: 500;

            i {
              margin-left: px(3);
              @include iconSemo($w: px(4), $h: px(7), $c: #2c64f3, $ro: 0);
            }

            &.up {
              color: #d31818;

              i {
                @include iconSemo($w: px(4), $h: px(7), $c: #d31818, $ro: 180);
              }
            }
          }
        }

        & ~ .sub_txt {
          display: block;
          margin-top: px(7);
          font-size: px(12);
          color: #999;
        }

        .nodata {
          margin-top: px(10);
          font-size: px(16);
          font-weight: 500;
          color: #cdcdcd;
        }
      }
    }

    .chart_list {
      margin-top: px(30);

      .chart_tit {
        display: flex;
        height: px(30);
        border-bottom: px(2) solid #222;

        li {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;

          &.current {
            a {
              color: $fcr;
              font-weight: 700;
            }
          }

          a {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            word-break: break-all;
            width: 100%;
            height: 100%;
            font-size: px(14);
            color: #999;
            font-weight: 300;
            letter-spacing: px(-2);
          }
        }
      }

      [class*='tab'] {
        display: none;
        width: 100%;
        min-height: px(260);
        margin-top: px(5);

        .tab_inner {
          display: flex;
          width: 100%;
          height: 100%;
          overflow-x: scroll;

          &.dim {
            @include pos(relative);
            overflow-x: hidden;
            min-width: 100%;

            &:after {
              content: '';
              display: inline-block;
              width: 100%;
              height: 100%;
              @include pos(absolute, $t: 0, $l: 0);
              background: rgba(255, 255, 255, 0.7);
            }

            &:before {
              content: '최근 실거래가 없습니다.';
              @include pos(absolute, $t: 50%, $l: 50%);
              transform: translate(-50%, -50%);
              display: block;
              width: 100%;
              text-align: center;
              font-size: px(11);
              color: #666;
              z-index: 1;
            }
          }
        }

        &.current {
          display: block;
        }
      }

      .tab_inner {
        position: relative;

        .lbl_chart {
          position: absolute;
          font-size: px(12);
          font-weight: 300;
          color: #fff;
          background-color: #222;
          padding: px(6) px(10);

          s {
            font-family: 'Roboto', 'Noto Sans KR', Dotum, Gulim, sans-serif;
            color: inherit;
          }
        }
      }
      .rMateH5__Root {
        .rMateH5__DataTip {
          background-color: #222;
          border-color: #222 !important;
          border-radius: 0;
          color: #fff;
          border: 0 none !important;
          transition: none;
          padding: 6px;
        }
        .rMateH5__Legend {
          left: auto !important;
          right: 0 !important;
        }
      }
    }

    .chart_loan {
      @include pos(relative);
      height: px(150);
      margin-top: px(20);
      margin-bottom: px(20);

      .label {
        @include pos(absolute, $t: px(10), $r: 0);
        width: px(73);
      }

      .chart_info {
        @include pos(absolute, $l: 50%, $b: 0);
        transform: translateX(-50%);
        width: px(310);
      }

      .chart_area {
        @include pos(relative);

        img {
          max-width: 100%;
        }

        .chart_inner {
          width: px(180);
          height: px(90);
          margin: 0 auto;
        }

        .total_price {
          @include pos(absolute, $b: px(0), $l: 50%);
          transform: translateX(-50%);
          text-align: center;

          &::before {
            content: '';
            display: inline-block;
            @include pos(absolute, $b: px(0), $l: 50%);
            width: px(144);
            height: px(76);
            background-image: url($urlImg + 'chart_loan_bg.png');
            transform: translateX(-50%);
            z-index: -1;
          }

          span {
            font-size: px(18);
            font-weight: 500;

            s {
              @include wfr;
              font-size: px(20);
            }
          }

          p {
            font-size: px(14);
            color: #999;
          }
        }
      }

      .chart_label {
        .percent {
          @include pos(absolute, $b: px(22));
          display: flex;
          justify-content: space-between;
          width: 100%;

          span {
            width: 19%;
            font-size: px(16);
            font-weight: 500;
            padding: 0 px(1);

            &:first-child {
              s {
                color: #24c2b5;
              }

              text-align: right;
            }

            &:last-child {
              s {
                color: #ff493b;
              }

              text-align: left;
            }

            s {
              @include wfr;
            }
          }
        }

        .price {
          display: flex;
          justify-content: space-between;
          margin-top: px(5);

          span {
            width: 24%;
            font-size: px(16);
            font-weight: 700;

            &:first-child {
              text-align: right;
            }

            &:last-child {
              text-align: left;
            }

            s {
              @include wfr;
            }
          }
        }
      }
    }

    .chart_info02 {
      border: px(2) solid #d6d6d6;
      border-radius: px(10);
      padding: px(25) px(15);

      .info {
        padding: 0 px(10);

        .th {
          flex: 0 auto;
        }

        .td {
          text-align: right;
          font-weight: 500;
          font-size: 0;

          .bu {
            border: px(1) solid #cccccc;
            padding: px(2) px(8);
            border-radius: px(11.5);
            font-size: px(12);
            color: #b9b9b9;
            margin-right: px(10);
          }

          .sub_txt {
            font-size: px(14);
          }

          ul {
            li {
              display: block;
              text-align: right;
              min-height: px(23);

              & ~ li {
                margin-top: px(5);
              }

              .sub_txt {
                display: inline-block;
                min-width: px(80);
              }
            }
          }
        }

        &.result {
          margin-top: px(15);
          padding-top: px(15);
          border-top: px(1) solid #d6d6d6;

          .sub_txt {
            font-size: px(16);
            font-weight: 700;
            color: #ff493b;
          }
        }
      }
    }
  }

  .apt_bbs_info {
    li {
      display: flex;
      align-items: flex-start;

      & ~ li {
        margin-top: px(15);
      }
    }

    .th {
      flex: 0 px(100);
      font-size: px(12);
      color: #666;
      line-height: px(18);
      text-align: left;
      font-weight: 400;
      min-height: px(18);
    }

    .td {
      flex: 1;
      font-size: px(14);
      color: #222;
      line-height: 1.3;
      word-break: keep-all;

      .r_txt {
        margin-left: px(3);
        display: inline-block;
        vertical-align: px(1);
        min-width: px(40);
        border-radius: px(9);
        padding: 0 px(10);
        border: px(1) solid $fcr;
        font-size: px(11);
        line-height: px(16);
        font-weight: 700;
        color: $fcr;
      }

      code.r_txt {
        font-size: px(12);
      }
    }

    & ~ .btn_wrap {
      margin-top: px(20);
    }
  }

  .amenities {
    .amenities_items {
      position: relative;
      padding-bottom: px(20);
      border-bottom: px(1) solid #efefef;

      & ~ .amenities_items {
        margin-top: px(20);
      }

      .btn_more {
        display: none !important;
        position: absolute;
        bottom: px(-11);
        left: 50%;
        transform: translateX(-50%);
        @include bgf;
        width: px(20);
        height: px(20);
        background-image: url($urlImg + 'btn_more_r.png');
        transition: transform 0.5s;
      }

      &.show {
        .btn_more {
          transform: translateX(-50%) rotate(-180deg);
        }
      }

      &.more {
        .btn_more {
          display: block !important;
        }
      }
    }

    .th {
      &[class*='icon_'] {
        display: block;
        font-size: px(14);
        font-weight: 500;
        color: #222;

        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          background-repeat: no-repeat;
          background-size: 100%;
          margin-right: px(5);
          width: px(26);
          height: px(26);
        }

        &.icon_subway {
          &:before {
            background-image: url($urlImg + 'icon_subway.png');
          }
        }

        &.icon_bus {
          &:before {
            background-image: url($urlImg + 'icon_bus.png');
          }
        }

        &.icon_school {
          &:before {
            background-image: url($urlImg + 'icon_school.png');
          }
        }

        &.icon_mart {
          &:before {
            background-image: url($urlImg + 'icon_mart.png');
          }
        }

        &.icon_hospital {
          &:before {
            background-image: url($urlImg + 'icon_hospital.png');
          }
        }

        &.icon_park {
          &:before {
            background-image: url($urlImg + 'icon_park.png');
          }
        }
      }
    }

    .td {
      &.sub_list {
        margin-top: px(12);
        font-size: px(14);

        li {
          display: flex;
          align-items: baseline;
          justify-content: space-between;

          & ~ li {
            margin-top: px(7);
          }
        }

        .sub_th {
          width: 70%;
          flex: 0 70%;
          color: #999;
          font-weight: 400;

          .tit {
            @include elip(l1);
            max-width: 75%;
            vertical-align: px(-2);
          }
        }

        .sub_td {
          width: 30%;
          flex: 0 30%;
          text-align: right;
          color: #222;
        }
      }
    }
  }

  .tb_apt {
    margin-top: px(25);

    & ~ .btn_wrap {
      margin-top: px(20);
    }
  }

  .btn_sel_wrap {
    .inner_wrap {
      .btn_sel {
        height: px(35);
        padding: 0 px(10);
        font-size: px(14);
        font-weight: 500;
        color: #222;
        text-align: left;
        padding-right: px(35);
        z-index: 2;
      }

      &:after {
        content: '';
        @include pos(absolute, $t: 50%, $r: px(7));
        width: px(20);
        height: px(20);
        background-image: url($urlImg + 'bg_select.png');
      }
    }
  }
}

.ly_apt_filter,
.ly_point_filter {
  .content {
    padding-top: px(95) !important;
  }

  .inner_wrap {
    padding-bottom: px(20);
  }

  .top_srch_wrap {
    .filter_tab_swipe {
      transform: none !important;

      .fix {
        position: fixed;
        top: px(50);
        background-color: #fff;
      }
    }

    .view {
      position: relative;

      .item {
        position: relative;
        display: block !important;
        border-bottom: 0;
        padding: px(30) px(20) 0;

        & ~ .item {
          padding: px(20) px(20) 0;
        }

        .tit {
          display: block;
          margin-bottom: px(10);
          font-size: px(14);
          font-weight: 400;
        }
      }
    }
  }

  .rdo_wrap {
    height: px(85);

    .rdo_item {
      height: 100%;
      border: 0;
      overflow: hidden;

      & ~ .rdo_item {
        margin-left: px(8);
      }

      [class*='ico_'] {
        &::before {
          content: '';
          @include pos(absolute, $t: 50%, $l: 50%);
          @include bgf($ps: true, $v: 100%);
          width: px(36);
          height: px(35);
        }

        &.ico_house_o {
          &::before {
            content: '';
            transform: translate(-50%, -75%);
            background-image: url($urlImg + 'icon_apt_off.png');
          }
        }

        &.ico_house_x {
          &::before {
            content: '';
            transform: translate(-50%, -75%);
            background-image: url($urlImg + 'icon_apt_no_off.png');
          }
        }

        &.ico_people_o {
          &::before {
            content: '';
            transform: translate(-50%, -75%);
            width: px(44);
            height: px(30);
            background-image: url($urlImg + 'icon_people_off.png');
          }
        }

        &.ico_people_x {
          &::before {
            content: '';
            transform: translate(-50%, -75%);
            width: px(26);
            height: px(30);
            background-image: url($urlImg + 'icon_people_no_off.png');
          }
        }

        &.ico_period_o {
          &::before {
            content: '';
            transform: translate(-50%, -75%);
            width: px(28);
            height: px(36);
            background-image: url($urlImg + 'icon_period_off.png');
          }
        }

        &.ico_period_x {
          &::before {
            content: '';
            transform: translate(-50%, -75%);
            width: px(28);
            height: px(36);
            background-image: url($urlImg + 'icon_period_no_off.png');
          }
        }
      }

      input {
        &:checked {
          + label {
            background-color: #e6fff9;
            border-color: #e6fff9;

            i {
              color: #222;
            }

            &.ico_house_o::before {
              background-image: url($urlImg + 'icon_apt_on.png');
            }

            &.ico_house_x::before {
              background-image: url($urlImg + 'icon_apt_no_on.png');
            }

            &.ico_people_o::before {
              background-image: url($urlImg + 'icon_people_on.png');
            }

            &.ico_people_x::before {
              background-image: url($urlImg + 'icon_people_no_on.png');
            }

            &.ico_period_o::before {
              background-image: url($urlImg + 'icon_period_on.png');
            }

            &.ico_period_x::before {
              background-image: url($urlImg + 'icon_period_no_on.png');
            }
          }
        }

        + label {
          box-sizing: border-box;
          border: 1px solid #efefef;

          i {
            font-size: px(12);
            color: #999;
          }
        }
      }

      i {
        @include pos(absolute, $t: 65%, $l: 50%);
        transform: translateX(-50%);
        width: 100%;
      }
    }
  }

  .page_info_txt {
    padding-top: px(20);
    text-align: center;
    font-size: px(12);
    color: #999;
  }

  .btn_tip {
    position: absolute;
    top: px(17);
    right: px(22);
    @include bgf;
    width: px(20);
    height: px(20);
    background-image: url($urlImg + 'btn_tip.png');
  }
}

.ly_apt_srch {
  .wrap {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .content {
    padding-top: px(50) !important;

    &.top_srch_wrap {
      animation: none !important;
    }
  }

  .header {
    .btn_prev {
      z-index: 2;
    }

    .form {
      max-height: px(50);
    }
  }

  .form {
    width: 100%;
    padding: 0 px(11);
  }

  .ip_box {
    border: 0;

    input {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      padding: 0 px(40) 0 px(34);
    }

    .lbl {
      left: px(34);
      font-size: px(14);
      font-weight: 300;
      color: #999;
    }

    button.del {
      right: px(10);
    }
  }

  .srch_list_wrap {
    top: px(50);

    .inner_wrap {
      min-height: calc(100vh - 50px);
      background-color: #fff;
    }

    .search_list {
      min-height: calc(100vh - 94px);
    }
  }

  .filter_tab_swipe {
    background-color: #fff;
    width: 100%;
    transform: none !important;
  }
}

.ly_apt_sale {
  &.ly_apt_join {
    .header {
      padding-bottom: 0 !important;
    }
  }

  &.ly_filter_detail {
    .wrap {
      .content {
        padding-bottom: 0 !important;
      }
    }
  }

  .nodata_wrap {
    padding: px(100) 0;
  }

  .header {
    display: block;
    border-bottom: 0 !important;

    header {
      display: flex;
      position: relative;
      width: 100%;
      height: px(50);
      align-items: center;

      .tit {
        text-align: left !important;
        margin-left: px(54);
      }
    }

    .info_header {
      &_default {
        align-items: center;
        height: px(50);
        padding: 0 px(15);
      }

      .btn_home,
      .btn_my,
      .btn_menu {
        position: relative;
        top: 0;
        right: auto;
        transform: none;
      }

      .btn_home {
        top: px(2);
      }
    }

    .info_banner {
      a {
        display: block;
      }
    }

    .info_desc {
      padding:  px(10) px(16);
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: px(45);
        min-height: px(36);
        padding: px(4) px(15);
        text-align: center;
        font-size: px(14);
        font-weight: 500;
        color: #222;
        background-color: #E1F5F5;
      }
    }

    .menu_wrap {
      padding: 0 px(12);
      font-size: 0;

      li {
        position: relative;
        display: inline-block;

        a,
        button {
          display: block;
          padding: px(9) px(8);
          font-size: px(14);
          color: #999;
        }

        & ~ li {
          margin-left: px(10);

          @include mq('small') {
            margin-left: px(0);
          }
        }

        &.active {
          a,
          button {
            color: #222;
            font-weight: 500;
          }

          &:after {
            content: '';
            position: absolute;
            top: px(4);
            right: px(4);
            width: px(4);
            height: px(4);
            border-radius: 50%;
            background-color: $fcr;
          }
        }
      }
    }

    .apt_srch_wrap {
      position: relative;
      padding-top: px(10);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: px(10);
        background-color: #f8f8f8;
      }
    }
  }

  .wrap {
    .content {
      padding-top: px(94);
    }
  }

  .apt_list_wrap {
    .item {
      .inner_wrap {
        position: relative;

        .tit_wrap {
          strong {
            padding-right: 35% !important;
          }
        }
      }

      &.link {
        .inner_wrap {
          .tit_wrap {
            strong {
              padding-right: px(50) !important;
            }
          }
        }
      }
    }

    .txt {
      margin-top: px(6);
      font-size: 0;

      span,
      code {
        display: inline-block;
        font-size: px(14);
        color: #15beb9;
        font-weight: 500;
      }

      code {
        margin-left: px(5);
      }

      &.end {
        span {
          color: #666;
        }
      }

      &.after {
        span {
          color: #b7aa7b;
        }
      }
    }

    .price {
      position: absolute;
      top: px(-5);
      right: px(0);
      font-size: px(19) !important;
      font-weight: 500 !important;
      color: #d31818 !important;

      code {
        font-size: px(20);
      }
    }
  }

  .ly_content {
    position: relative;
    padding-top: px(10);
    background-color: #fff;

    &.ly_mh {
      min-height: calc(100vh - 210px);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: px(10);
      background-color: #f8f8f8;
    }

    .view {
      padding: px(20);

      .item {
        position: relative;

        & ~ .item {
          margin-top: px(20);
        }
      }

      .tit {
        display: block;
        margin-bottom: px(10);
        font-size: px(14);
        font-weight: 400;
      }
    }
  }

  .ly_detail {
    position: relative;
    padding-top: px(10);
    background-color: #fff;
    overflow-x: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: px(10);
      background-color: #f8f8f8;
    }

    .view {
      padding: px(20);
    }
  }

  .btn_calc {
    position: absolute;
    top: 0;
    right: 0;
    @include bgf;
    width: px(18);
    height: px(22);
    background-image: url($urlImg + 'btn_cal.png');
  }

  .btn_expect_wrap {
    bottom: px(20);
  }
}

.toggle_wrap {
  display: block !important;

  .th {
    position: relative;

    .btn_toggle {
      position: relative;
      padding-right: px(25);
      color: #666;

      &:after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) rotate(180deg);
        content: '';
        @include bgf;
        width: px(20);
        height: px(20);
        background-image: url($urlImg + 'btn_toggle.png');
        transition: 0.3s;
      }
    }

    .txt {
      position: absolute;
      top: 0;
      right: 0;
      font-size: px(12);
      font-weight: 300;
      color: #999;
    }
  }

  .view_toggle {
    display: none;
    margin-top: px(10);
  }

  &.active {
    .btn_toggle {
      &:after {
        transform: translateY(-50%) rotate(0);
      }
    }

    .view_toggle {
      display: block;
    }
  }
}

// 청약 그래프
.analyze_wrap {
  .btn_tip {
    display: inline-block;
    margin-left: px(4);
    @include bgf;
    width: px(20);
    height: px(20);
    background-image: url($urlImg + 'btn_tip.svg');
  }

  .tit {
    line-height: px(20);

    .btn_tip {
      margin-left: px(8);
      vertical-align: top;
    }
  }

  .analyze_info {
    padding: px(16) px(20);

    ul {
      li {
        display: flex;
        align-items: center;

        strong {
          flex: 0 auto;
          font-size: px(14);
          font-weight: 400;
          color: #999;
        }

        p {
          flex: 1;
          text-align: right;
          font-size: px(14);

          span {
            font-size: px(16);
            font-weight: 500;

            code {
              font-size: px(25);
              font-weight: 700;
            }
          }
        }

        & ~ li {
          margin-top: px(9);
        }
      }
    }
  }

  h2.tit {
    padding: px(15) px(20) px(12);
    font-size: px(16);
    font-weight: 700;
    background-color: #f8f8f8;
  }

  h3.s_tit {
    margin: 0 px(20);
    padding: px(12) 0;
    font-size: px(14);
    font-weight: 300;
    border-bottom: px(1) solid #efefef;

    strong {
      font-weight: 700;
    }

    & ~ h3.s_tit {
      margin-top: px(28);
    }
  }

  .my_info_wrap {
    @include pos(relative);

    &.fixed {
      @include pos(fixed, $t: px(65), $l: 50%);
      transform: translateX(-50%);
      background-color: #fff;
      width: px(1100);
      height: px(115);
      z-index: 4;

      .btn_close {
        top: px(22);
      }

      .info_inner {
        width: 100%;
        height: px(83);
        top: px(40);
      }
    }

    .btn_close {
      @include pos(absolute, $t: px(-215), $l: 50%);
      transform: translateX(-50%);
      width: px(40);
      height: px(40);
      background-color: #222;
      @include bdr($o: true);
      box-shadow: 0 3px 10px -4px #616161;
      cursor: pointer;
      z-index: 2;

      &:after {
        content: '';
        @include pos(absolute, $t: 50%, $l: 50%);
        @include bgf;
        width: px(24);
        height: px(14);
        transform: translate(-50%, -40%);
        background-image: url($urlImg + 'ico_fold_v2.png');
      }
    }
  }

  .info_inner {
    @include pos(absolute, $t: px(-196));
    width: 100%;
    display: flex;
    background-color: #fff;
    border: 1px solid #222;
    border-top: 2px solid #222;
    box-shadow: 0 px(10) px(20) px(-10) rgba(0, 0, 0, 0.15);
    cursor: pointer;

    li {
      display: flex;
      @include pos(relative);
      width: 33.33%;
      height: px(80);
      justify-content: space-between;
      align-items: center;
      padding: px(30);

      & ~ li {
        border-left: 1px solid #222;
      }

      .sub {
        font-size: px(14);
        color: #999;
        @include elip(l1);
        min-width: px(100);
      }

      .main {
        font-size: px(20);
        color: #222;
        font-weight: 500;
        @include elip(l1);

        em {
          display: inline-block;
          font-size: px(22);
          @include wfr;
        }
      }
    }
  }

  .con_list {
    padding: 0 px(50) px(50);

    .item {
      padding: px(40) px(40) px(50);
      border: 1px solid #dadada;
      background-color: #fff;
      @include bdr($v: 15);
      box-shadow: 0px 9px 13px -12px #a5a5a5;

      & ~ .item {
        margin-top: px(30);
      }

      .tit {
        @include pos(relative);
        font-size: px(20);
        color: #222;
        font-weight: 500;
        letter-spacing: -1px;

        b {
          @include wfr;
          margin-right: px(3);
          font-weight: 500;
        }

        em {
          @include pos(absolute, $t: -65px, $l: -50px);
          @include wfr;
          font-weight: 900;
          font-size: px(95);
          color: #24c3b6;
          opacity: 0.12;
          letter-spacing: -5px;
        }
      }

      .sub_tit {
        font-size: px(16);
        font-weight: 500;
      }

      .txt_caption {
        font-size: px(14);
        color: #999;
        text-align: right;
        margin-bottom: px(5);
      }
    }
  }

  .sec03 {
    padding-bottom: px(30);
  }

  .sec {
    & ~ .sec {
      margin-top: px(40);
    }

    & > strong {
      @include pos(relative);
      display: block;
      font-weight: 500;
      font-size: px(18);
      color: #222;
      margin-bottom: px(50);
      z-index: 1;

      s {
        @include wfr;
        font-weight: 700;
        color: #dbb77e;
      }

      .q_mark {
        @include pos(relative, $t: px(4), $r: px(-4));
        display: inline-block;
        transform: unset;

        .txt {
          font-weight: 400;
        }
      }
    }

    .graph {
      opacity: 0;
      transform: translateY(30px);
      width: px(150);
      margin: px(35) auto px(30);

      &.rader {
        width: px(220);
        margin-right: px(60);

        .rader_lbl {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: px(28);

          strong {
            font-size: px(18);
            font-weight: 500;
            margin-right: px(10);
          }

          span {
            @include wfr;
            display: flex;
            align-items: center;
            font-size: px(28);
            font-weight: 300;
            color: #656565;

            b {
              font-size: px(34);
              font-weight: 700;
              margin-right: px(4);
              color: #222;
            }
          }
        }
      }

      .dount_area {
        @include pos(relative);
        @include bdr($o: true);
        width: px(150);
        height: px(150);
        background-color: #fff;
        box-shadow: 0 0 30px -6px rgba(0, 0, 0, 0.2);

        .circle_chart {
          width: px(130);
          height: px(130);
          @include pos(absolute, $t: 50%, $l: 50%);
          transform: translate(-50%, -50%);
          opacity: 1;

          &.active {
            // opacity: 1;
            .circle_progress {
              transition: all 1s ease-out;
            }
          }

          &.full {
            .chart-box {
              padding: px(13);
            }

            .circle_progress {
              stroke-width: 15 !important;
            }

            [class*='per-'] {
              width: px(90) !important;
              height: px(90) !important;

              span {
                font-size: px(24) !important;
              }
            }
          }

          .chart-box {
            width: 100%;
            height: 100%;
            padding: px(11);

            svg {
              @include pos(relative);
              width: 100%;
              height: 100%;
              overflow: visible;
              transform: rotateY(180deg) rotate(-90deg);
              z-index: 1;

              .circle_progress {
                fill: transparent;
                stroke-width: 18;
              }

              .marker {
                fill: #fff;
                transform-origin: 50% 50%;
                transform: rotate(-90deg);
                stroke-width: 1;
                transition: transform 1s ease;
                z-index: 3;
              }
            }

            [class*='per-'] {
              @include bdr($o: true);
              @include pos(absolute, $t: 50%, $l: 50%);
              display: flex;
              justify-content: center;
              align-items: center;
              transform: translate(-50%, -50%);
              width: px(90);
              height: px(90);
              box-shadow: 0 0 px(20) 0 rgba(0, 0, 0, 0.15);
              text-align: center;

              &.per-num {
                @include wfr;
                font-size: px(22);
                font-weight: 300;

                &.per {
                  font-size: px(26);
                  font-weight: 600;

                  span {
                    margin-right: 0;
                  }

                  s {
                    font-size: px(26);
                    font-weight: 600;
                  }
                }

                &.rank {
                  span {
                    margin-right: 0;
                  }

                  s {
                    font-size: px(20);
                    font-weight: 500;
                  }
                }

                span {
                  font-weight: 700;
                  font-size: px(26);
                }
              }

              &.per-txt {
                font-weight: 700;
                font-size: px(16);
              }

              &.per-txt02 {
                font-weight: 700;

                &.down {
                  span:after {
                    transform: rotate(180deg);
                  }
                }

                span {
                  font-size: px(20) !important;

                  &:after {
                    content: '';
                    @include bgf;
                    margin-left: px(5);
                    width: px(17);
                    height: px(19);
                    vertical-align: -2px;
                    background-image: url($urlImg + 'icon_graph_up.png');
                  }
                }
              }

              &.per-point {
                flex-direction: column;

                &.per {
                  s {
                    @include wfr;
                    font-size: px(26);
                    font-weight: 700;
                  }

                  .num {
                    font-weight: 700;
                  }
                }

                span {
                  font-size: px(18);
                  font-weight: 500;

                  &.txt01 {
                    font-size: px(18) !important;
                    font-weight: 700;
                    line-height: 1;
                  }

                  s {
                    @include wfr;
                    font-size: px(20);
                  }
                }

                p {
                  font-size: px(14);
                  font-weight: 300;
                  color: #999;
                  margin-top: px(3);
                }
              }
            }
          }
        }
      }

      .noti {
        margin-top: px(30);
        font-size: px(14);
        font-weight: 300;
        color: #666;
      }

      .btn_inner {
        margin-top: px(15);
        text-align: center;

        button {
          position: relative;
          font-size: px(14);
          font-weight: 300;
          color: #999;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: px(-2);
            width: 100%;
            height: px(1);
            background-color: #999;
          }
        }
      }
    }

    .ty_graph {
      & + .ty_graph {
        margin-top: px(50);
      }

      &.best {
        .caption {
          &::before {
            background-image: url($urlImg + 'emoji_best.png');
          }

          em {
            background-color: rgba(110, 245, 234, 0.5);
          }
        }

        .progress {
          &.single {
            .point {
              background-color: #27d8c9;
            }
          }
        }

        .dount_area {
          #dount_best {
            .color_1 {
              stop-color: #27d8c9;
            }

            .color_2 {
              stop-color: #77f3e8;
            }
          }
        }
      }

      &.good {
        .caption {
          &::before {
            background-image: url($urlImg + 'emoji_good.png');
          }

          em {
            background-color: rgba(113, 223, 113, 0.5);
          }
        }

        .progress {
          &.single {
            .point {
              background-color: #5ed55e;
            }
          }
        }

        .dount_area {
          #dount_good {
            .color_1 {
              stop-color: #5ed55e;
            }

            .color_2 {
              stop-color: #99f49b;
            }
          }
        }
      }

      &.normal {
        .caption {
          &::before {
            background-image: url($urlImg + 'emoji_normal.png');
          }

          em {
            background-color: rgba(255, 234, 59, 0.5);
          }
        }

        .progress {
          &.single {
            .point {
              background-color: #ffde47;
            }
          }
        }

        .dount_area {
          #dount_normal {
            .color_1 {
              stop-color: #ffde47;
            }

            .color_2 {
              stop-color: #fff0aa;
            }
          }
        }
      }

      &.bad {
        .caption {
          &::before {
            background-image: url($urlImg + 'emoji_bad.png');
          }

          em {
            background-color: rgba(255, 73, 59, 0.3);
          }
        }

        .progress {
          &.single {
            .point {
              background-color: #f7c0bc;
            }
          }
        }

        .dount_area {
          #dount_bad {
            .color_1 {
              stop-color: #ff493b;
            }

            .color_2 {
              stop-color: #ff938b;
            }
          }
        }
      }
    }

    .tb_apt {
      opacity: 0;
      transform: translateY(30px);
      margin: px(20) 0 px(30);

      table {
        thead {
          th {
            &:first-child {
              text-align: left;
              padding-left: px(10);
            }
          }
        }

        tbody {
          tr {
            td {
              &:first-child {
                text-align: left;

                span {
                  @include elip('l1');
                  padding-left: px(10);
                }
              }

              &:last-child {
                @include wfr;
                font-weight: 500;
              }

              &.high_low {
                span {
                  @include wfr;
                  font-weight: 700;

                  &:first-child {
                    color: #15beb9;
                  }

                  &:last-child {
                    color: #ff493b;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.toggle_wrap {
      margin: 0 px(20);

      hr {
        margin: 0 px(-20);
        border: 0;
        height: px(10);
        background-color: #f8f8f8;
      }

      .btn_toggle {
        position: relative;
        display: block;
        padding: px(15) 0;
        width: 100%;
        font-size: px(16);
        font-weight: 700;
        text-align: left;
        @include elip('l1');
        padding-right: px(30);

        &:after {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%) rotate(0);
          content: '';
          @include bgf;
          width: px(28);
          height: px(28);
          background-image: url($urlImg + 'btn_toggle02.png');
          transition: 0.3s;
        }
      }

      .view_toggle {
        margin-top: 0;
        padding-top: px(20);
        border-top: px(2) solid #222;
      }

      &.active {
        .btn_toggle {
          &:after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }

        .view_toggle {
          display: block;
        }
      }
    }

    .bubble {
      [class*='tit_'] {
        display: block;
        margin-bottom: px(10);
        font-size: px(14);
        font-weight: 400;

        code {
          font-weight: bold;
        }
      }

      .tit_high {
        code {
          color: #2fdbcc;
        }
      }

      .tit_low {
        margin-top: px(15);

        code {
          color: #ff5345;
        }
      }

      ul {
        opacity: 0;
        transform: translateY(30px);
        position: relative;
        overflow: hidden;
        padding: px(20) px(15);
        border-radius: px(10);

        &.high {
          background-color: #f2fffe;

          .rank {
            background: linear-gradient(315deg, #2dd9cb, #72f1e6);

            &::after {
              content: '';
              @include pos(absolute, $l: 10px, $b: -8px);
              border-top-color: #46e1d5;
              clip: rect(0px, px(20), px(11), px(12));
            }
          }
        }

        &.low {
          background-color: #fff4f3;

          .rank {
            background: linear-gradient(138deg, #ff938b, #ff493b);

            &::after {
              content: '';
              @include pos(absolute, $l: 30px, $b: -8px);
              border-top-color: #ff5f53;
              clip: rect(0px, px(8), px(8), 0);
            }
          }
        }

        li {
          float: left;
          width: 33.3%;
          padding: 0 px(5);
          text-align: center;

          .rank {
            @include pos(relative);
            @include bdr($v: 7);
            @include wfr;
            display: inline-block;
            padding: px(9) px(13);
            font-size: px(11);
            font-weight: 700;
            line-height: 1;
            color: #fff;
            overflow: visible;

            &::after {
              content: '';
              @include pos(absolute, $l: 50%, $b: -8px);
              @include iconSemo($w: px(10), $h: px(9), $ro: 0);
            }
          }

          p {
            @include elip('l1');
            display: block;
            width: 100%;
            margin-top: px(15);
            font-size: px(12);
            font-weight: 300;
            color: #666;
            letter-spacing: px(-1);
          }

          span {
            display: block;
            margin-top: px(3);
            font-size: px(12);
            font-weight: 500;

            s {
              @include wfr;
              font-size: px(13);
              font-weight: 700;
            }
          }
        }
      }
    }

    .caption_wrap {
      opacity: 0;
      transform: translateY(30px);
      text-align: center;
      margin-top: px(35);
    }

    .caption {
      background-color: #dffffc;
      @include bdr($v: 10);

      &[class*='emoji'] {
        opacity: 0;
        transform: translateY(30px);
        @include pos(relative);
        margin: 0 px(16);
        padding: px(20);
        font-weight: 300;
        border-radius: px(5);
        background-color: #f3f3f3;

        &::before {
          content: '';
          display: inline-block;
          @include pos(absolute);
          width: px(28);
          height: px(28);
          background-size: cover;
        }

        &.error {
          &::before {
            background-image: url(../images/emoji_before.png) !important;
          }
        }

        p {
          margin-left: px(43);
          font-size: px(14);
          line-height: 1.7;

          em {
            position: relative;
            color: #222;
            font-weight: 700;
          }

          b {
            display: inline-block;
            color: #222;
            font-weight: 500;
          }
        }

        strong {
          margin-left: px(43);
          font-size: px(14);

          code {
            font-size: px(16);
            font-weight: 500;
          }
        }
      }

      &.list {
        box-sizing: border-box;
        display: inline-block;
        min-width: px(133);
        padding: px(15);
        margin: 0 auto;
      }

      .high_low {
        margin-top: px(20);
        margin-left: px(64);

        li {
          display: inline-flex;
          align-items: center;

          & ~ li {
            margin-left: px(20);
          }

          span {
            font-size: px(14);
            font-weight: 300;
            color: #666;

            b {
              font-weight: 700;
              color: #222;
            }
          }

          &.high {
            strong {
              border-color: #2bbcb0;
              color: #2bbcb0;
            }
          }

          &.low {
            strong {
              border-color: #ff493b;
              color: #ff6358;
            }
          }

          strong {
            @include bdr($v: 10);
            @include wfn;
            display: inline-block;
            padding: px(3) px(8);
            margin: 0 px(8) 0 0;
            font-size: px(12);
            border: px(1) solid;
            background-color: #fff;
          }
        }
      }

      p {
        line-height: 1.5;
        color: #666;

        & + strong {
          margin-top: px(7);
        }
      }

      strong {
        display: block;
        font-size: px(16);
        font-weight: 500;

        code {
          margin-right: px(5);
        }

        span {
          @include wfr;
          @include bdr($v: 8);
          display: inline-block;
          line-height: normal;
          margin-bottom: px(-3);
          font-size: px(14);
          font-weight: 700;
          padding: 0 px(8);
          color: #15beb9;
          background-color: #fff;
          border: px(1) solid #15beb9;
        }
      }

      ul {
        li {
          font-size: px(14);
          font-weight: 300;
          text-align: left;
          color: #222;

          span {
            display: inline-block;
            width: px(40);
            color: #666;
          }

          &.tit {
            color: #222;
            font-weight: 500;
            margin-bottom: px(8);
          }

          & ~ li {
            margin-top: px(5);
          }
        }
      }

      > .noti {
        margin-top: px(22);
        margin-bottom: 0;
        text-align: left;

        p {
          font-size: px(14);
          color: #999;
        }
      }
    }

    .progress {
      opacity: 0;
      transform: translateY(30px);
      @include pos(relative);
      padding: 0 px(16);

      &.multi {
        height: px(144);
        margin-top: px(45);

        .bg {
          @include pos(relative);
          top: px(40);
          width: 100%;
          height: px(7);
          background-color: #ececec;

          &:after {
            content: '';
            @include bdr($v: 2);
            @include pos(absolute, $t: 50%, $l: 0);
            transform: translateY(-50%);
            display: inline-block;
            width: px(4);
            height: px(15);
            background-color: #999;
          }

          &:before {
            content: '';
            @include bdr($v: 2);
            @include pos(absolute, $t: 50%, $r: 0);
            transform: translateY(-50%);
            display: inline-block;
            width: px(4);
            height: px(15);
            background-color: #999;
          }
        }

        .point {
          @include pos(absolute, $l: 0%);
          z-index: 2;

          &:after {
            content: '';
            @include bdr($o: true);
            @include pos(absolute, $l: 50%, $t: px(-2));
            transform: translateX(-50%);
            display: inline-block;
            width: px(12);
            height: px(12);
            box-shadow: 0 0px 6px -1px rgba(0, 0, 0, 0.15);
            border: px(2) solid #fff;
          }

          &.my {
            left: 5%;
            z-index: 1;

            &:after {
              background-color: #666;
            }

            span {
              @include pos(absolute, $l: 50%, $t: -57px);
              background-color: #666;

              &:after {
                top: px(34);
                transform: translateX(-50%) rotate(360deg);
                border-top-color: #666;
              }

              &::before {
                @include pos(absolute, $t: auto, $b: -20px);
                width: px(1);
                height: px(20);
                border-left: 1px dashed #ccc;
              }
            }
          }

          &.low {
            &:after {
              background-color: #ff493b;
            }

            span {
              background-color: #ff493b;

              &:after {
                border-top-color: #ff493b;
              }
            }
          }

          &.max {
            &:after {
              background-color: #27d8c9;
            }

            span {
              background-color: #27d8c9;

              &:after {
                border-top-color: #27d8c9;
              }
            }
          }

          span {
            box-sizing: border-box;
            @include pos(absolute, $l: 50%, $t: px(30));
            transform: translateX(-50%);
            border-radius: 50%;
            color: #fff;
            font-size: px(13);
            width: px(35);
            height: px(35);
            display: block;
            line-height: 1;
            text-align: center;
            padding-top: px(6.5);

            strong,
            em {
              display: block;
              font-size: px(10);
              font-weight: 300;
              color: #fff;
              letter-spacing: -1px;
            }

            &::after {
              content: '';
              @include pos(absolute, $l: 50%, $t: -5px);
              @include iconSemo($w: px(4), $h: px(6), $ro: 180);
              transform: translateX(-50%) rotate(180deg);
            }

            &::before {
              content: '';
              @include pos(absolute, $t: px(-20));
              width: px(1);
              height: px(20);
              border-left: 1px dashed #ccc;
            }

            code {
              @include wfr;
              color: inherit;
              font-size: px(12);
              margin-top: px(1);
              font-weight: 500;
            }

            em {
              font-size: px(12);
            }
          }
        }

        .progress_lbl {
          display: flex;
          justify-content: space-between;
          margin-top: px(47);

          span {
            @include wfr;
            font-size: px(14);
            color: #666;
            font-weight: 300;
          }
        }

        .progress_label {
          margin-top: px(57);
          text-align: center;
          font-size: 0;

          span {
            font-size: px(12);
            font-weight: 300;
            color: #999;

            &:before {
              content: '';
              width: px(8);
              height: px(8);
              border-radius: 50%;
              display: inline-block;
              background-color: #666;
              margin-right: px(4);
            }

            &.low {
              &:before {
                background-color: #ff493b;
              }
            }

            &.max {
              &:before {
                background-color: #27d8c9;
              }
            }

            & ~ span {
              margin-left: px(14);
            }
          }
        }
      }
    }

    .people {
      opacity: 0;
      transform: translateY(30px);
      display: flex;
      margin: px(30) 0 px(20);

      li {
        @include pos(relative);
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 px(35);

        .inner {
          display: block;
          position: relative;
          width: 100%;
        }

        & ~ li {
          &::after {
            content: '';
            @include pos(absolute, $l: 0, $t: 50%);
            transform: translateY(-50%);
            display: inline-block;
            width: px(1);
            height: px(40);
            background-color: #ebebeb;
          }

          code {
            right: px(35);
          }
        }

        strong {
          display: block;
          font-size: px(14);
          font-weight: 300;

          &::before {
            content: '';
            @include bgf;
            width: px(41);
            height: px(36);
            margin-bottom: px(5);
            display: block;
            background-image: url($urlImg + 'ico_people_ty2.png');
          }
        }

        code {
          position: absolute;
          top: 0;
          right: 0;
          font-size: px(22);
          font-weight: 700;

          &[class*='count_'] {
            &::before {
              content: attr(per);
            }
          }
        }
      }
    }

    .chart {
      opacity: 0;
      transform: translateY(30px);

      &.my_point {
        position: relative;
        padding: px(25) px(16) 0;

        .chart_lbl {
          span {
            font-size: px(14);
            color: #666;

            &::before {
              content: '';
              display: inline-block;
              vertical-align: middle;
              margin: px(-2) px(5) 0 0;
            }

            &.house {
              &::before {
                background-image: url($urlImg + 'ico_house_ty2.png');
                width: px(19);
                height: px(20);
                margin-top: px(-5);
              }
            }

            &.family {
              &::before {
                background-image: url($urlImg + 'ico_people_ty3.png');
                width: px(21);
                height: px(17);
              }
            }

            &.account {
              &::before {
                background-image: url($urlImg + 'ico_bank_ty2.png');
                width: px(23);
                height: px(16);
              }
            }
          }
        }

        .before_wrap {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.7);

          .inner_wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;

            .txt {
              font-size: px(16);
              font-weight: 500;
              color: #222;
            }

            .btn {
              margin-top: px(15);
              min-width: px(150);
              padding: 10px 0;
              border-radius: px(5);
              font-size: px(16);
              color: #fff;
              background-color: #222;
              box-shadow: 0 0 px(10) rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .map {
      border-radius: px(16);
    }

    .noti {
      opacity: 0;
      transform: translateY(30px);
      padding: 0 px(20) 0;
      font-size: px(12);
      color: #999;
      text-align: center;
      font-weight: 300;
      line-height: 1.5;
    }

    .apt_info_wrap {
      display: none;

      .tit_wrap {
        position: relative;

        .tit {
          display: inline-block;
          font-size: px(14);
          font-weight: 300;
        }

        .txt {
          position: relative;
          margin-top: px(8);
          padding-left: px(10);
          font-size: px(14);
          line-height: px(22);
          font-weight: 300;
          color: #999;

          &:before {
            content: ':';
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .info {
          position: absolute;
          top: px(3);
          right: 0;
          font-size: px(12);
          font-weight: 300;
          color: #999;
        }
      }

      [class*='icon_'] {
        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          background-repeat: no-repeat;
          background-size: 100%;
          margin-right: px(5);
          width: px(26);
          height: px(26);
        }

        &.icon_subway {
          &:before {
            background-image: url($urlImg + 'icon_subway.png');
          }
        }

        &.icon_bus {
          &:before {
            background-image: url($urlImg + 'icon_bus.png');
          }
        }

        &.icon_school {
          &:before {
            background-image: url($urlImg + 'icon_school.png');
          }
        }

        &.icon_mart {
          &:before {
            background-image: url($urlImg + 'icon_mart.png');
          }
        }

        &.icon_hospital {
          &:before {
            background-image: url($urlImg + 'icon_hospital.png');
          }
        }

        &.icon_park {
          &:before {
            background-image: url($urlImg + 'icon_park.png');
          }
        }

        &.icon_park02 {
          &:before {
            background-image: url($urlImg + 'icon_park02.png');
          }
        }

        &.icon_etc {
          &:before {
            background-image: url($urlImg + 'icon_etc.png');
          }
        }

        &.icon_mart02 {
          &:before {
            background-image: url($urlImg + 'icon_mart02.png');
          }
        }
      }

      .facility_info {
        opacity: 0;
        transform: translateY(30px);
        position: relative;
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          top: px(10);
          bottom: px(10);
          left: 50%;
          width: px(1);
          background-color: #ebebeb;
        }

        li {
          display: flex;
          align-items: center;
          float: left;
          width: 50%;
          font-size: px(14);
          font-weight: 500;
          line-height: px(26);

          & ~ li {
            margin-top: px(4);
          }

          &:nth-child(odd) {
            padding-right: px(28);
          }

          &:nth-child(even) {
            padding-left: px(28);
          }

          strong {
            flex: 1;

            &:before {
              content: '';
              display: inline-block;
              vertical-align: middle;
              background-repeat: no-repeat;
              background-size: 100%;
              margin-right: px(5);
              width: px(26);
              height: px(26);
            }
          }

          span {
            flex: 0 auto;
          }
        }
      }

      .noti {
        margin: px(20) 0 px(30);
        padding: 0;
      }

      .map_sec {
        position: relative;
        margin: 0 px(-20);
        height: px(220);
        overflow: hidden;
      }

      .map_info {
        padding: px(30) 0;
      }

      .form_wrap {
        margin-top: px(10);

        .select_box {
          select {
            font-size: px(14);
          }

          &:after {
            background-image: url(../images/bg_select.png);
          }
        }
      }

      .map_info_list {
        > li {
          & ~ li {
            margin-top: px(20);
            padding-top: px(20);
            border-top: px(1) solid #f2f2f2;
          }

          &:last-child {
            margin-bottom: px(20);
            padding-bottom: px(20);
            border-bottom: px(1) solid #f2f2f2;
          }
        }

        .txt {
          margin-top: px(14);
          font-size: px(14);
          color: #666;
        }

        .tit_wrap {
          display: flex;
          align-items: baseline;
          justify-content: space-between;

          strong {
            font-size: px(14);
            font-weight: 500;
          }

          span {
            min-width: px(42);
            padding: px(10.5) 0;
            border-radius: 50%;
            font-size: px(12);
            font-weight: 500;
            color: #fff;
            text-align: center;
            background-color: #15beb9;

            code {
              font-size: px(16);
              font-weight: 700;
            }

            &.low {
              background-color: #ccc;
            }
          }
        }

        .sub_list {
          margin-top: px(12);
          font-size: px(14);

          li {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            & ~ li {
              margin-top: px(7);
            }
          }

          .sub_th {
            width: 70%;
            flex: 0 70%;
            color: #666;
            font-weight: 400;

            .tit {
              @include elip(l1);
              max-width: 75%;
              vertical-align: -2px;
            }
          }

          .sub_td {
            width: 30%;
            flex: 0 30%;
            text-align: right;
            font-weight: 500;
            color: #222;
          }
        }
      }

      .map_info_txt {
        margin-bottom: px(30);

        li {
          position: relative;
          padding-left: px(7);
          font-size: px(12);
          font-weight: 300;
          color: #999;
          line-height: px(20);

          &:before {
            content: '*';
            position: absolute;
            top: px(2);
            left: 0;
          }
        }
      }

      .caption[class*='emoji'] {
        margin: 0;
      }

      .apt_list {
        opacity: 0;
        transform: translateY(30px);
        margin-top: px(30);

        > li {
          & ~ li {
            margin-top: px(20);
            padding-top: px(20);
            border-top: px(1) solid #f2f2f2;
          }

          &:last-child {
            margin-bottom: px(20);
            padding-bottom: px(20);
            border-bottom: px(1) solid #f2f2f2;
          }
        }

        .tit_wrap {
          span {
            display: inline-block;
            padding: px(5) px(10);
            font-size: px(14);
            color: #999;
            border: px(1) solid #999;
          }

          strong {
            display: block !important;
            margin-top: px(14);
            font-size: px(14);
            font-weight: 700;
            @include elip('l1');
          }
        }

        .sub_list {
          margin-top: px(12);
          font-size: px(14);

          li {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            & ~ li {
              margin-top: px(7);
            }

            .sub_th {
              font-weight: 300;
              color: #666;
            }

            [class*='num_'] {
              margin-left: px(3);
              font-weight: 700;

              &:after {
                content: '';
                display: inline-block;
                @include bgf;
                width: px(11);
                height: px(13);
                margin-left: px(3);
                vertical-align: -1px;
                background-image: url($urlImg + 'icon_up.png');
              }
            }

            .num_up {
              color: #1c57cf;
            }

            .num_down {
              color: #d31818;

              &:after {
                background-image: url($urlImg + 'icon_down.png');
              }
            }

            .day {
              margin-left: px(3);
              border-radius: px(7.5);
              border: px(1) solid #15beb9;
              padding: 0 px(10);
              font-size: px(12);
              line-height: 1;
              font-weight: 700;
              color: #15beb9;
              vertical-align: px(2);
            }
          }
        }
      }

      .chart {
        margin: px(30) 0 px(40);
      }
      .rMateH5__Root {
        .rMateH5__DataTip {
          background-color: #222;
          border-color: #222 !important;
          border-radius: 0;
          color: #fff;
          border: 0 none !important;
          transition: none;
          padding: 6px;
        }
        .rMateH5__Legend {
          left: auto !important;
          right: 0 !important;
          font-size: 12px;
        }
      }
    }
  }

  > .btn_inner {
    padding-top: px(50);
    text-align: center;
    background-color: #fff;

    button {
      font-size: px(16);
      color: #222;
      padding: px(15) px(30);
      border: 1px solid #222;

      &::after {
        bottom: px(-6);
        background-color: #c3c3c3;
      }
    }
  }
}

.ly_apt_cost_detail {
  .ly_content {
    background-color: #fff;
  }

  hr {
    border: 0;
    padding: 0;
    margin: 0;
    height: px(10);
    background-color: #f8f8f8;
  }

  .view {
    padding: 0 px(20);
  }

  .tit_wrap {
    .tit {
      display: block;
      padding: px(15) 0;
      font-size: px(16);
      font-weight: 700;
    }
  }

  .col_max {
    display: flex;
    margin-top: px(40);
    padding-bottom: px(20);

    .inner_wrap {
      position: relative;
      width: 100%;
    }
  }

  [class*='progress_step'] {
    &.progress_step_main {
      .total_cost {
        @include pos(relative);
        border: 1px solid #d8d8d8;
        border-bottom: none;
        width: 100%;
        height: px(8);

        span {
          @include pos(absolute, $b: px(17), $l: 50%);
          transform: translateX(-50%);
          font-size: px(18);
          font-weight: 500;
          color: #222;
          @include wfr;

          em {
            @include wfn;
          }
        }
      }

      .progress_bar {
        display: inline-flex;
        margin: px(5) 0 px(55);
        width: 100%;
        height: px(40);

        [class*='step_'] {
          @include pos(relative);
          height: 100%;

          > span {
            @include pos(absolute, $t: 50%, $l: 50%);
            transform: translate(-50%, -50%);
            @include wfr;
            font-size: px(14);
            font-weight: 500;
            color: #fff;
            line-height: 1;

            em {
              padding-left: px(2);
            }
          }

          .step_lbl {
            @include pos(absolute, $b: px(-10), $l: 50%);
            transform: translateX(-50%);
            height: auto;
            width: 100%;

            .dec_dash {
              @include pos(absolute, $t: px(4));
              width: 100%;
              border-top: 1px dashed #e0e0e0;

              &::after,
              &::before {
                content: '';
                display: inline-block;
                width: px(1);
                height: px(10);
                background-color: #e0e0e0;
              }

              &::after {
                @include pos(absolute, $t: px(-5), $r: px(0));
              }

              &::before {
                @include pos(absolute, $t: px(-5), $l: px(-1));
              }
            }

            .text_area {
              @include pos(absolute, $l: 50%, $t: px(13));
              transform: translateX(-50%);
              z-index: 1;

              p {
                white-space: nowrap;
                text-align: center;
                font-size: px(12);
                font-weight: 500;
                @include wfr;

                em {
                  @include wfn;
                }

                &.sub_txt {
                  white-space: nowrap;
                  margin-top: 0;
                  font-size: px(12);
                  font-weight: 400;
                  color: #999;
                }
              }
            }
          }

          &.step_01 {
            width: 20%;
            background-color: #888888;
          }

          &.step_02 {
            width: 60%;
            background: repeating-linear-gradient(
              315deg,
              #36bfb4,
              #36bfb4 5px,
              #2cb5aa 5px,
              #2cb5aa 5.8px
            );
          }

          &.step_03 {
            width: 20%;
            background-color: #f16d63;
          }
        }
      }
    }

    &.progress_step_detail {
      margin-top: px(9);
      height: px(240);

      .progress_bar {
        li {
          & ~ li {
            margin-top: px(40);
          }

          & ~ li:last-of-type {
            margin-top: px(38);
          }

          strong {
            display: inline-block;
            padding: px(4) px(8);
            margin-bottom: px(8);
            border: px(1) solid #cdcdcd;
            border-radius: px(12);
            font-size: px(14);
            line-height: 1;
            color: #999;
            font-weight: 400;
          }

          [class*='progress_bg'] {
            width: 100%;
            height: px(8);
            background-color: #f6f6f6;

            &.progress_bg_md {
              display: flex;
              justify-content: space-between;
            }

            [class*='step'] {
              @include pos(relative);
              height: 100%;

              .step_lbl {
                @include pos(absolute, $b: px(-10), $l: px(2));
                font-size: px(14);
                @include wfr;

                em {
                  @include wfn;
                }
              }

              &.step_01 {
                width: 20%;
                background: repeating-linear-gradient(
                  315deg,
                  #767676,
                  #767676 5px,
                  #626262 5px,
                  #626262 5.8px
                );
              }

              &[class*='step_02'] {
                @include pos(relative);

                &::after {
                  content: '';
                  transform: translateY(-39%);
                  width: px(1);
                  height: px(209);
                  border-left: 1px dashed #e0e0e0;
                  background-color: #fff;
                  z-index: 1;
                }

                &.step_02_1 {
                  margin-left: 20%;
                  width: 40%;
                  background: repeating-linear-gradient(
                    315deg,
                    #2fc5b9,
                    #2fc5b9 5px,
                    #2fbfb3 5px,
                    #2fbfb3 5.8px
                  );
                  margin-right: 1px;

                  &::after {
                    @include pos(absolute, $t: 0, $l: px(-1));
                  }
                }

                &.step_02_2 {
                  margin-right: 20%;
                  width: 20%;
                  background: repeating-linear-gradient(
                    315deg,
                    #26a69b,
                    #26a69b 5px,
                    #199a8e 5px,
                    #199a8e 5.8px
                  );

                  &::after {
                    @include pos(absolute, $t: 0, $r: 0);
                  }

                  .step_lbl {
                    left: 0;
                  }
                }
              }

              &.step_03 {
                margin-left: 80%;
                width: 20%;
                background: repeating-linear-gradient(
                  315deg,
                  #f27c73,
                  #f27c73 5px,
                  #df685f 5px,
                  #df685f 5.8px
                );
              }
            }
          }
        }
      }
    }
  }

  .toggle_wrap {
    position: relative;
    margin-bottom: px(30);

    &:last-child {
      margin-bottom: 0;

      .view_toggle {
        .price_detail {
          ul {
            &:last-child {
              padding-bottom: px(30);
            }
          }
        }
      }
    }

    &.nodata {
      > div {
        opacity: 0.3;
      }

      &:before {
        content: '';
        @include pos(absolute, $t: 0, $l: 0);
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      &:after {
        content: '입력 된 비용 정보가 없어요!';
        @include pos(absolute, $t: 50%, $l: 50%);
        transform: translate(-50%, -50%);
        font-size: px(12);
        color: #fff;
        text-align: center;
        padding: px(9) px(20);
        background-color: #666;
        box-shadow: 0 0 px(10) 0 rgba(0, 0, 0, 0.3);
        white-space: nowrap;
        z-index: 1;
      }
    }

    &.active {
      .btn_toggle_wrap {
        .btn_toggle {
          &:after {
            transform: translateY(-50%) rotate(0);
          }
        }
      }
    }

    .view_toggle {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: px(-20);
        right: px(-20);
        bottom: 0;
        height: px(10);
        background-color: #f8f8f8;
      }
    }
  }

  .btn_toggle_wrap {
    position: relative;
    display: block;
    margin: 0 px(-20);
    background-color: #f8f8f8;

    .btn_tip {
      z-index: 1;
      position: absolute;
      top: px(11);
      left: px(80);
      @include bgf;
      width: px(20);
      height: px(20);
      background-image: url($urlImg + 'btn_tip.png');
    }

    .txt_toggle {
      code {
        margin-right: 0 !important;
      }

      &:after {
        display: none !important;
      }
    }

    .btn_toggle,
    .txt_toggle {
      position: relative;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      padding: px(10) px(20);
      width: 100%;
      font-size: px(16);
      font-weight: 700;
      text-align: left;

      strong {
        font-weight: 700;
      }

      code {
        margin-right: px(25);
      }

      &:after {
        position: absolute;
        top: 50%;
        right: px(20);
        transform: translateY(-50%) rotate(180deg);
        content: '';
        @include bgf;
        width: px(20);
        height: px(20);
        background-image: url($urlImg + 'btn_toggle.png');
        transition: 0.3s;
      }
    }
  }

  .price_detail {
    ul {
      padding-bottom: px(14);
      margin-bottom: px(14);
      border-bottom: px(1) solid #eaeaea;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: px(30);
        border-bottom: 0;
      }

      li {
        padding-left: px(12);
        @include pos(relative);
        display: flex;
        justify-content: space-between;
        font-size: px(12);
        color: #999;

        strong,
        span {
          font-weight: 300;
        }

        .td_pri01 {
          font-weight: 400;
          color: #24c3b6;
        }

        .td_pri02 {
          font-weight: 400;
          color: #17a094;
        }

        &[class*='price'] {
          font-size: px(14);
          color: #222;

          strong {
            font-weight: 400;
          }

          span {
            font-weight: 500;
          }

          &.price_fir {
            &::after {
              background-color: #888;
            }
          }

          &.price_sec {
            &::after {
              background-color: #2bbcb0;
            }
          }

          &.price_thr {
            &::after {
              background-color: #f16d63;
            }
          }

          &::after {
            content: '';
            @include pos(absolute, $l: px(0), $t: 50%);
            transform: translateY(-50%);
            width: px(8);
            height: px(8);
            background-color: #888;
          }
        }

        &:nth-child(n + 3) {
          justify-content: flex-end;
        }

        & ~ li {
          padding-top: px(7);
        }

        span {
          @include wfr;

          em {
            @include wfn;
            color: inherit;
          }
        }
      }
    }

    &.type02 {
      margin-top: px(15);

      ul {
        li {
          &:nth-child(n + 3) {
            justify-content: space-between;
          }
        }

        &:last-child {
          padding-bottom: px(20);
        }
      }
    }
  }

  .graph {
    position: relative;
    max-width: px(220);
    margin: 0 auto;
    padding-bottom: px(16);

    .pct_wrap {
      position: absolute;
      left: 2rem;
      top: 20%;

      strong {
        display: block;
        font-size: px(12);
        color: #999;
        font-weight: 300;
      }

      p {
        font-family: 'Roboto', 'Noto Sans KR', Dotum, Gulim, sans-serif;
        font-size: px(18);
        color: #222;
        font-weight: 500;
        margin-top: px(2);
      }

      &[class*='dash'] {
        top: inherit !important;

        p::after {
          content: '';
          @include pos(absolute);
          display: inline-block;
          width: px(12);
          height: px(12);
        }

        &.dash_r {
          p::after {
            right: px(-15);
            bottom: px(-3);
            border-top: 1px dashed #b3b3b3;
          }
        }

        &.dash_l {
          p::after {
            left: px(-15);
            bottom: px(-3);
            border-top: 1px dashed #b3b3b3;
          }
        }

        &.dash_tr {
          p::after {
            right: px(-15);
            bottom: px(-3);
            border-top: 1px dashed #b3b3b3;
            border-right: 1px dashed #b3b3b3;
          }
        }

        &.dash_tl {
          p::after {
            left: px(-15);
            bottom: px(-3);
            border-top: 1px dashed #b3b3b3;
            border-left: 1px dashed #b3b3b3;
          }
        }

        &.dash_br {
          p::after {
            right: px(-15);
            bottom: px(7);
            border-bottom: 1px dashed #b3b3b3;
            border-right: 1px dashed #b3b3b3;
            height: px(23);
          }
        }

        &.dash_bl {
          p::after {
            left: px(-15);
            bottom: px(7);
            border-bottom: 1px dashed #b3b3b3;
            border-left: 1px dashed #b3b3b3;
            height: px(23);
          }
        }
      }
    }
  }

  .info_txt_wrap {
    padding: px(25) 0 px(40);

    li {
      & ~ li {
        margin-top: px(15);
      }
    }

    .tit {
      position: relative;
      padding-left: px(11);
      font-size: px(16);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: px(5);
        height: px(5);
        border-radius: 50%;
        background-color: #2bbcb0;
      }
    }

    .sub_txt {
      margin-top: px(5);
      padding-left: px(11);
      font-size: px(14);
      font-weight: 300;
      color: #666;
    }
  }
}

.ly_apt_cost_filter {
  .content {
    padding-top: 50px;
    padding-bottom: 55px;
  }

  .ly_content {
    background-color: #fff;
  }

  .map_sec {
    position: relative;
    height: px(200);
    overflow: hidden;
  }

  .filter_wrap {
    padding-bottom: px(45);

    .item {
      position: relative;
      padding: px(20) px(20) 0;

      & ~ .item {
        padding-top: px(15);
      }

      .tit {
        display: block;
        margin-bottom: px(10);
        font-size: px(14);
        font-weight: 400;
      }

      .btn_tip {
        position: absolute;
        top: px(17);
        right: px(22);
        @include bgf;
        width: px(20);
        height: px(20);
        background-image: url($urlImg + 'btn_tip.png');
      }
    }
  }

  .rdo_wrap {
    .rdo_item {
      height: px(45);
      position: relative;
      border: 1px solid #efefef;
      border-left: 0;

      i {
        color: #999;
        font-size: px(14);
      }

      &:first-of-type {
        border-left: 1px solid #efefef;
      }

      label {
        font-size: 14px;
      }

      input:checked + label {
        color: #fff;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        left: -1px;
        top: -1px;
        border: 1px solid #47e1d4;
        background-color: #effefe;
        font-weight: 500;

        i {
          color: #2bbcb0;
        }
      }
    }
  }

  .col_wrap {
    display: flex;

    .form {
      flex: 1;

      & ~ .form {
        margin-left: px(8);
      }
    }
  }

  .rdo_answer {
    display: none;
    margin-top: px(8);
  }

  .ip_box input {
    padding: 0 px(40) 0 px(34);
    height: px(50);
  }
}

.ly_point_filter {
  .content {
    &.end {
      .btn_area_wide.btm {
        background-color: transparent;

        &:before,
        &:after {
          content: '';
          z-index: -1;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
        }

        &:before {
          top: px(-10);
          background: linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.9) 15%,
            white 100%
          );
        }

        &:after {
          top: px(20);
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: #fff;
        }
      }
    }
  }

  .view {
    display: none;
    padding-bottom: px(40) !important;
    min-height: calc(100vh - 210px);

    .btn_wrap {
      margin-top: px(25);
      text-align: center;
      font-size: px(14);
      color: #999;
      font-size: 0;

      .btn {
        min-width: px(100);
        padding: px(11) px(10);
        border: px(1) solid #b9b9b9;
        border-radius: px(5);
        font-size: px(14);
        color: #999;

        & ~ .btn {
          margin-left: px(8);
        }
      }
    }

    .rdo_answer {
      .item:first-child {
        padding-top: px(20);
      }
    }
  }

  .tab_tit_wrap {
    position: relative;
    padding-bottom: px(8);
    margin-bottom: px(20);
    border-bottom: px(1) solid #222;

    strong {
      display: block;
      font-size: px(16) !important;
      font-weight: 700 !important;
    }

    p {
      font-size: px(12);
      font-weight: 300;
      color: #999;
    }

    .btn_tip {
      position: static;
      margin-left: px(6);
      vertical-align: top;
    }
  }

  .rdo_answer {
    display: none;
  }

  .ip_box {
    input {
      padding: 0 px(40) 0 px(34);
      height: px(50);
    }

    .lbl {
      &.code {
        font-size: px(16);
      }
    }
  }

  .sum_wrap {
    .tit_wrap {
      span,
      strong {
        display: block;
      }

      span {
        font-size: px(15);
      }

      strong {
        margin-top: px(4);
        font-size: px(24);
        font-weight: 700;

        code,
        em {
          color: #24c3b6 !important;
        }
      }
    }

    .count_list {
      margin-top: px(23);

      li {
        padding: px(20) 0;
        border-bottom: px(1) solid #efefef;
        display: flex;
        align-items: center;

        strong {
          flex: 1;
          font-size: px(14);
          text-align: left;
        }

        span {
          flex: 0 auto;
          font-size: px(16);

          code {
            margin-right: px(2);
            font-size: px(22);
            font-weight: 700;
          }
        }

        &:first-child {
          padding-top: 0;
        }

        &:before {
          content: '';
          @include bgf;
          width: px(30);
          height: px(27);
          background-position: left center;
        }

        &.item01 {
          &:before {
            background-image: url($urlImg + 'icon_apt.png');
            background-size: px(23) px(25);
          }
        }

        &.item02 {
          &:before {
            background-image: url($urlImg + 'icon_people.png');
            background-size: px(28) px(20);
          }
        }

        &.item03 {
          &:before {
            background-image: url($urlImg + 'icon_period.png');
            background-size: px(25) px(17);
          }
        }
      }
    }
  }

  .btn_sum {
    display: none;
  }

  .sum_total_wrap {
    margin-top: px(16);
    text-align: center;

    .info_txt {
      position: relative;
      padding-left: px(18);
      font-size: px(14);
      line-height: px(20);
      font-weight: 300;
      color: #999;
      text-align: left;

      &:before {
        content: '※';
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .into_txt02 {
      display: block;
      margin-top: px(50);
      font-size: px(16);
      font-weight: 500;
    }
  }

  .sum_info_wrap {
    margin: px(-20);
    text-align: left;
    box-shadow: 0 px(-3) px(8) rgba(0, 0, 0, 0.1) !important;
    display: flex;
    align-items: center;
    background-color: #fff;

    .tit_wrap {
      flex: 1;

      .title,
      .count {
        display: inline-block;
        vertical-align: middle;
        padding: px(22) 0;
      }

      .title {
        padding-left: px(20);
        font-size: px(14);

        &:before {
          content: '';
          @include bgf;
          width: px(28);
          height: px(25);
          margin-right: px(5);
          background-position: left center;
        }

        &[data-name='무주택자'] {
          &:before {
            background-image: url($urlImg + 'icon_apt.png');
            background-size: px(23) px(25);
          }
        }

        &[data-name='부양가족'] {
          &:before {
            background-image: url($urlImg + 'icon_people.png');
            background-size: px(28) px(20);
          }
        }

        &[data-name='청약통장'] {
          &:before {
            background-image: url($urlImg + 'icon_period.png');
            background-size: px(25) px(17);
          }
        }
      }
    }

    .total_wrap {
      flex: 0 auto;
      text-align: right;

      .count {
        margin-right: px(10);
        font-size: px(16);

        @include mq('small') {
          font-size: px(14);
        }

        code {
          margin-right: px(2);
          font-size: px(22);
          font-weight: 700;

          @include mq('small') {
            font-size: px(16);
          }
        }
      }

      .total {
        display: inline-block;
        background-color: #24c3b6;
        padding: px(22) px(11) px(20) px(22);
        border-top-left-radius: px(37);
        border-bottom-left-radius: px(37);

        @include mq('small') {
          padding: px(26) px(11) px(20) px(26);
        }

        span {
          font-size: px(16);
          color: #fff;

          @include mq('small') {
            font-size: px(14);
          }
        }

        code,
        .slash {
          font-size: px(24);
          font-weight: 300;
          color: #fff;
          letter-spacing: -1.5px;

          @include mq('small') {
            font-size: px(16);
          }
        }

        .slash {
          margin: 0 px(5);
        }

        .bold {
          font-weight: 700;
        }
      }
    }
  }
}

.ly_apt_info {
  .ly_content {
    background-color: #fff;
  }

  .info_wrap {
    padding: 0 px(25) px(80);

    .ani {
      transform: translateY(80px);
      opacity: 0;
    }

    .item01 {
      .img_wrap {
        padding-top: px(50);
      }
    }

    .item02 {
      .tit,
      .txt {
        text-align: right;
      }
    }

    .img_wrap {
      text-align: center;
      padding: px(80) 0 px(16);

      img {
        max-width: px(260);
      }
    }

    .tit {
      display: block;
      font-size: px(22);
      line-height: 1.3;
      font-weight: 700;

      code {
        display: block;
        font-size: px(40);
        color: #27d8c9;
      }
    }

    .txt {
      margin-top: px(20);
      font-size: px(14);
      font-weight: 300;
      color: #777777;
      line-height: px(24);

      strong {
        font-weight: 700;
      }
    }
  }
}

.ly_apt_join {
  min-height: 100vh;

  .header {
    padding-bottom: px(15);
  }

  .ly_content {
    padding-top: px(0);

    //min-height:calc(100vh - 105px);
    &:before {
      display: none;
    }

    .view {
      padding-bottom: px(40);
    }
  }

  .cert_wrap {
    .form_wrap {
      .form {
        .ip_box {
          &.ip_del {
            button.del {
              right: px(60) !important;
            }
          }
        }
      }
    }
  }

  .form_wrap {
    .form {
      .ip_box {
        input {
          padding: 0 px(40) 0 px(34);
          height: px(50);
        }

        &.ip_del {
          button.del {
            right: px(80) !important;
          }
        }

        .lbl {
          font-size: px(16);
        }
      }

      .select_box {
        select {
          font-weight: 500;
        }
      }
    }
  }

  .col_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form_wrap {
      flex: 1;

      .form {
        .ip_box {
          &.ip_del {
            button.del {
              right: px(20) !important;
            }
          }
        }
      }
    }

    .btn_txt {
      position: relative;
      padding-bottom: px(3);
      margin-left: px(7);
      flex: 0 px(50);
      font-size: px(14);
      color: #999;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: px(1);
        background-color: #999;
      }
    }

    .security_wrap {
      position: relative;
      flex: 0 px(124);
      height: px(80);
      margin-right: px(8);
      border: px(1) solid #efefef;
      text-align: center;

      img {
        max-width: 100%;
      }

      .btn_reset {
        content: '';
        position: absolute;
        right: px(9);
        bottom: px(9);
        @include bgf;
        width: px(26);
        height: px(26);
        background-image: url($urlImg + 'btn_reset.png');
      }
    }
  }

  .phone_save {
    margin-top: px(20);
    font-size: 0;

    .checkbox {
      display: inline-block;

      & ~ .checkbox {
        margin-left: px(30);
      }

      &.i_all {
        input {
          & + label {
            font-size: px(14);
            font-weight: 400;

            i {
              width: px(22);
              height: px(19);
              vertical-align: px(-4);

              &:before {
                top: 0;
                background-size: auto px(18) !important;
              }
            }
          }
        }
      }
    }
  }
}

.ly_apt_mypage {
  .ly_content {
    background-color: #fff;

    hr {
      margin: 0 px(-20);
      border: 0;
      height: px(10);
      background-color: #f8f8f8;
    }

    section {
      position: relative;
      padding: px(20);
      overflow: hidden;

      & ~ section {
        padding-top: px(30);

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: px(-20);
          left: px(-20);
          height: px(10);
          background-color: #f8f8f8;
        }
      }

      &:last-child {
        padding-bottom: px(40);
      }

      .sec_tit {
        display: block;
        font-size: px(14);
        font-weight: 300;
        color: #999;
      }

      &.my_info {
        padding: px(25) px(20);

        .btn_wrap {
          margin-top: 0;
          text-align: left;

          li {
            & ~ li {
              margin-top: px(8);
            }

            .btn_txt_big {
              font-size: px(20);
              position: relative;
              padding-bottom: px(4);
              color: #222;
              font-weight: 700;

              &:before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: px(2);
                background-color: #222;
              }
            }
          }
        }
      }

      .inner_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: px(13);

        .phone_num {
          flex: 1;
          font-weight: 700;
          font-size: px(20);
        }

        .btn_txt {
          flex: 0 auto;
          font-size: px(14);
          color: #999;
          position: relative;
          padding-bottom: px(3);
          color: #999;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: px(1);
            background-color: #999;
          }

          &.type1 {
            color: #15beb9;

            &:before {
              background-color: #15beb9;
            }
          }
        }
      }

      .email_info {
        font-size: px(14);
        color: #999;

        &_btn {
          .btn_txt {
            + .btn_txt {
              margin-left: px(4);
            }
          }
        }

        > strong {
          font-weight: 400;
          color: #222;
        }

        > em {
          font-size: px(16);
          color: #222;
        }
      }

      ul,
      .link_wrap {
        margin-top: px(14);
      }

      .item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: px(23);
        text-align: left;

        & ~ .item {
          margin-top: px(7);
        }

        .item_tit {
          flex: 1;
          font-size: px(16);
          font-weight: 400;
          color: #222;
        }

        .toggle_chk {
          flex: 0 auto;

          input {
            @include checkToggle;
            vertical-align: middle;
          }
        }
      }

      .link_wrap {
        .item {
          &:after {
            content: '';
            width: px(28);
            height: px(28);
            margin-right: px(-10);
            @include bgf;
            background-image: url($urlImg + 'icon_arr02.png');
          }
        }
      }
    }
  }
}

.ly_apt_allim {
  .ly_content {
    background-color: #fff;
  }

  .nodata_wrap {
    padding-top: px(190);
  }
}

.ly_apt_ver {
  .ly_content {
    position: relative;
    height: calc(100vh - 150px);
    background-color: #fff;
  }

  .ver_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: px(14);
    font-weight: 500;

    .txt {
      line-height: 1.5;

      span {
        display: block;
        font-size: px(11);
        color: #999;
      }

      &:before {
        content: '';
        display: block !important;
        margin: 0 auto px(8);
        width: px(35);
        height: px(38);
        @include bgf;
        background-image: url($urlImg + 'logo_ver.png');
      }
    }
  }
}

.ly_apt_error {
  .ly_content {
    background-color: #fff;
  }

  .error_wrap,
  .ivr_wrap {
    padding: 0 px(45);
    text-align: center;
    font-size: px(14);
    font-weight: 500;

    @include mq('small') {
      padding: 0 px(20);
    }

    .tit {
      display: block;

      &:before {
        content: '';
        display: block !important;
        margin: px(80) auto px(12);
        width: px(48);
        height: px(41);
        @include bgf;
        background-image: url($urlImg + 'icon_error.png');
      }
    }

    .txt {
      margin-top: px(25);
      padding: px(14) 0;
      border-top: px(1) solid #e8e8e8;
      border-bottom: px(1) solid #e8e8e8;
      font-size: px(14);
      line-height: px(20);
      font-weight: 300;
      color: #999;
    }
  }

  .ivr_wrap {
    font-size: px(20);
    font-weight: 700;
  }

  .customer_info {
    text-align: center;

    span {
      display: block;
      font-size: px(14);
      color: #999;
    }

    code {
      display: block;
      font-size: px(20);
      line-height: 1.5;
      font-weight: 700;
    }

    .mail {
      padding: 0;
      color: #222;
      padding-bottom: px(50);
      font-family: 'Roboto', 'Noto Sans KR', Dotum, Gulim, sans-serif;
    }
  }

  ul.customer_info {
    text-align: center;

    li {
      display: block;
      font-size: 0;

      & ~ li {
        margin-top: px(4);
      }

      span {
        display: inline-block;
        font-size: px(14);
        line-height: 1;

        &.th {
          font-size: px(14);
          font-weight: 400;
          color: #999;
        }

        &.td {
          margin-left: px(5);
          font-size: px(13);
          font-weight: 700;
          color: #222;

          code {
            line-height: 1;
            font-size: px(14);
            display: inline-block;
          }
        }
      }
    }

    & ~ .btn_area_half {
      padding-top: px(35);
    }
  }
}

#mw-company {
  background-color: #fff;

  .tit {
    padding-bottom: px(12);
    border-bottom: px(2) solid #222;
  }

  .txt_wrap {
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 25%;
      position: absolute;
      left: 0;
      bottom: -20px;
      background: linear-gradient(
        bottom,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .scroll_y {
    max-height: px(438);

    @include mq('small') {
      max-height: px(320);
    }

    @include iscroll('y');

    ol {
      counter-reset: dots;

      li {
        padding: px(11) 0;
        font-size: px(16);
        color: #999;
        border-bottom: px(1) solid #e8e8e8;

        &:before {
          counter-increment: dots;
          content: counter(dots);
          margin-right: px(7);
          font-family: 'Roboto', 'Noto Sans KR', Dotum, Gulim, sans-serif;
        }

        &:nth-child(-n + 9):before {
          content: '0' counter(dots);
        }

        &.point {
          font-weight: 700;
          color: #2bbcb0;
        }
      }
    }
  }
}

.step_info_wrap {
  font-size: 0;
  padding: px(25) px(20) 0;

  span {
    display: inline-block;
    width: px(6);
    height: px(6);
    border-radius: px(3);
    background-color: #cdcdcd;
    transition: width 0.5s;
    font-size: 0;

    & ~ span {
      margin-left: px(5);
    }

    &.active {
      width: px(20);
      background-color: #2bbcb0;
    }
  }
}

.btn_expect_wrap {
  position: fixed;
  z-index: 10;
  bottom: px(30);
  right: 0;
  width: 100%;
  padding: 0 px(10);
  animation: w-full-wrap 0.2s linear 3s forwards;

  &.active {
    bottom: px(85);
  }

  .btn {
    display: block;
    float: right;
    width: 100%;
    height: px(50);
    box-shadow: 0 px(3) px(10) rgba(0, 0, 0, 0.4);
    font-size: px(14);
    font-weight: 200;
    text-align: center;
    color: #fff;
    border-radius: px(25);
    background-color: #444;
    animation: w-full 0.2s linear 2s forwards;

    span {
      display: inline-block;
      overflow: hidden;
      vertical-align: top;
      line-height: px(50);

      &.full {
        font-size: px(16);
        animation: w-full02 0.2s linear 2s forwards;

        &:before {
          content: '';
          @include bgf;
          width: px(28);
          height: px(26);
          margin-right: px(12);
          vertical-align: px(-5);
          background-image: url($urlImg + 'ico_fix.png');
        }
      }

      &.simple {
        width: 0;
        line-height: 1;
        font-size: px(14);
        font-weight: 500;
        animation: w-full03 0.2s linear 2s forwards;

        &:before {
          content: '';
          @include bgf;
          display: block;
          margin: 0 auto px(6);
          width: px(31);
          height: px(17);
          background-image: url($urlImg + 'ico_fix02.png');
        }
      }
    }
  }
}

.btn_sel_wrap {
  .inner_wrap {
    position: relative;
    padding-bottom: 0;

    .lbl {
      display: block !important;
      background-color: #fff;

      &.hide {
        display: none !important;
      }
    }

    .btn_sel {
      position: relative;
      width: 100%;
      height: px(50);
      padding: 0 px(15);
      font-size: px(16);
      font-weight: 500;
      color: #222;
      text-align: left;
      padding-right: px(35);
      z-index: 2;

      .lbl {
        right: px(38);
      }

      .sel_txt {
        @include elip('l1');
        line-height: 1;
        max-width: 85%;
      }

      &:disabled {
        border-color: #efefef;
        background-color: #efefef;
        color: rgba(34, 34, 34, 0.5);
      }
    }

    &:after {
      content: '';
      @include pos(absolute, $t: 50%, $r: px(12));
      width: px(28);
      height: px(28);
      transform: translateY(-50%);
      @include bgf;
      background-image: url($urlImg + 'bg_select02.png');
      z-index: 2;
    }
  }
}

.ly_info_swiper {
  .wrap {
    border-top-left-radius: 0;
  }

  .btn_info_reset {
    display: none;
    position: absolute;
    right: px(46);
    top: px(15);
    z-index: 1;

    i {
      @include bgf;
      width: px(28);
      height: px(28);
      @include svgfill(#222, 'btn_reset.svg');
    }
  }

  .swiper-container {
    position: relative;
    width: 100%;
    height: 100%;

    .swiper-pagination {
      font-size: 0;
      position: absolute;
      top: px(39);
      left: px(30);
      z-index: 1;

      .swiper-pagination-bullet {
        display: inline-block;
        width: px(6);
        height: px(6);
        border-radius: px(3);
        background-color: #cdcdcd;
        transition: width 0.5s ease-out;

        & ~ .swiper-pagination-bullet {
          margin-left: px(5);
        }

        &.swiper-pagination-bullet-active {
          width: px(20);
          background-color: #2bbcb0;
        }
      }
    }
  }

  .swiper-wrapper {
    height: px(390);

    .swiper-slide {
      position: relative;
      width: 100%;
      height: 100%;
      vertical-align: top;
      box-sizing: border-box;
      padding: px(59) px(30) 0;
      font-size: px(20);
      background-color: #fff;
      text-align: left;

      .slide_tit {
        font-size: px(24);
        line-height: px(32);
        font-weight: 300;
        white-space: initial;

        @include mq('small') {
          br {
            display: none;
          }
        }
      }

      .sub_txt {
        margin-top: px(5);
        min-height: px(40);
        font-size: px(14);
        line-height: px(20);
        font-weight: 300;
        color: #999;
        white-space: normal;
      }

      .form_ani {
        display: flex;
        margin-top: px(17);
        height: px(50);
        border: px(2) solid #222;
        align-items: center;

        .box {
          position: relative;
          flex: 1;
          text-align: left;

          .show01 {
            padding-left: px(14);
            font-size: px(16);
            color: #999;
          }

          .show02 {
            margin-left: px(15);
            font-size: px(22);
            color: #15beb9;
            font-weight: 700;
            display: none;
            overflow: hidden;
            white-space: nowrap;
            vertical-align: middle;
            border-right: px(2) solid #15beb9;
            padding-right: px(2);
            animation: typing 0.3s steps(6, end), blink-caret 0.75s step-end;
          }

          em {
            position: absolute;
            top: 50%;
            right: px(14);
            transform: translateY(-50%);
            font-size: px(20);
            font-weight: 500;
          }
        }

        &.on {
          .box {
            .show01 {
              display: none;
            }

            .show02 {
              display: inline-block;
            }
          }
        }

        &.srch_ani {
          .box {
            .show02 {
              font-size: px(16);
              vertical-align: -2px;
              animation: typing02 2s steps(40, end), blink-caret 0.75s step-end;
            }

            &:after {
              content: '';
              @include pos(absolute, $t: 50%, $r: px(13));
              @include bgf;
              transform: translateY(-50%);
              width: px(28);
              height: px(28);
              background-image: url($urlImg + 'btn_srch.svg');
            }
          }
        }
      }

      .select_ani_wrap {
        display: flex;
        margin-top: px(17);

        .select_ani {
          flex: 1;
          margin-right: px(5);

          & ~ .select_ani {
            margin-right: 0;
            margin-left: px(5);
          }

          .form_ani {
            margin-top: 0;
          }
        }
      }

      .select_ani {
        position: relative;
        z-index: 1;

        .form_ani {
          .show02 {
            font-size: px(16);
            border-right: 0;
          }

          &.on {
            .box {
              .show01 {
                display: none;
              }

              .show02 {
                display: block;
                animation: none;
              }
            }
          }
        }

        .box {
          &:after {
            content: '';
            @include pos(absolute, $t: 50%, $r: px(13));
            @include bgf;
            transform: translateY(-50%);
            width: px(28);
            height: px(28);
            background-image: url($urlImg + 'bg_select_ani.png');
            background-color: #fff;
            transition: all 0.3s ease-out;
          }

          &.on {
            &:after {
              transform: translateY(-50%) rotate(-180deg);
            }
          }
        }

        .selet_detail {
          display: none;
          overflow: hidden;
          position: absolute;
          top: px(50);
          left: 0;
          right: 0;

          .inner_wrap {
            box-sizing: border-box;
            position: relative;
            padding: px(3);
            border: px(1) solid #222;
            border-top: 0;
            background-color: #fff;

            .item {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 px(11);
              height: px(38);
              font-size: px(14);
              color: #666;
              transition: all 0.3 ease-out;
              background-color: #fff;

              &.on,
              &:active {
                background-color: #e6fff9;
              }
            }
          }
        }
      }

      .cursor {
        opacity: 0;
        position: absolute;
        bottom: -30px;
        right: -30px;
        width: px(38);
        height: px(38);
        border-radius: 50%;
        background-color: #15beb9;
        box-shadow: 0 0 px(10) rgba(12, 110, 102, 0.29);
      }

      .btn_wrap {
        margin-top: px(25);

        .btn_next {
          position: relative;
          padding: 0 px(29);
          min-width: px(150);
          height: px(50);
          border-radius: px(25);
          background-color: #222;
          color: #fff;
          font-size: px(16);
          font-weight: 500;
        }
      }

      .btn_txt {
        position: absolute;
        bottom: px(30);
        right: px(30);
        font-size: px(14);
        color: #999;
        float: right;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: px(1);
          background-color: #999;
        }
      }
    }
  }
}

.ly_chance {
  .content {
    padding: px(30);

    .tit {
      font-size: px(24);
      line-height: px(30);
      font-weight: 300;
      letter-spacing: -1px;
    }
  }

  .chk_list {
    margin-top: px(14);

    li ~ li {
      margin-top: px(6);
    }

    .item {
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      @include checkbox;

      input {
        & + label {
          display: flex;
          align-items: center;
          width: 100%;
          padding: px(17);
          border-radius: px(5);
          font-size: px(14);
          font-weight: 400;
          border: 1px solid #d6d6d6;

          .bu_txt {
            flex: 0 px(42);
          }

          .tit_wrap {
            flex: 1;
            min-width: 0;

            .tit {
              @include elip('l1');
              display: block;
              line-height: 1;
            }
          }

          code {
            flex: 0 auto;
            margin-top: px(2);
          }

          .txt {
            font-size: px(15);
          }
        }

        &:checked + label {
          border-color: #222222;
        }
      }
    }
  }
}

#mw-bookmark {
  .content {
    padding-bottom: 0 !important;
  }
}

// 2022.04.26 ~~ 메인 개편
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.css');

.map_top {
  position: relative;
  height: calc(100vh - 14.3rem);
}

.map_bottom {
  position: relative;
  min-height: px(111);
  // margin: px(-32) 0 px(-32);
  // padding-bottom: px(32);
  z-index: 10;

  &::before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 1;
    transition: all 0.3s;
    pointer-events: none;
  }

  &.scroll_start:before {
    background: rgba(0, 0, 0, 0.2);
    pointer-events: all;
  }

  * {
    font-family: 'pretendard', 'Noto Sans KR';
  }

  &_content {
    position: relative;
    min-height: px(111);
    padding: 0 px(20) 0;
    background: #fff;
    box-shadow: 0 px(-3) px(20) rgba(0, 0, 0, 0.16);
    border-radius: px(25) px(25) 0 0;
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      top: px(20);
      left: 50%;
      width: px(32);
      height: px(2);
      background: #ccc;
      border-radius: px(2);
      transform: translateX(-50%);
    }

    &:after {
      content: '';
      position: fixed;
      bottom: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: 1000;
    }

    .content_title {
      font-size: px(20);
      font-weight: 700;
      line-height: 1.18;
      margin-bottom: px(10);

      img {
        margin-right: px(5);
        width: px(23);
        height: px(23);
      }

      &.lg {
        margin-bottom: px(15);
        font-size: px(22);
      }

      .text_label {
        margin-left: px(4);
      }

      + p {
        margin-bottom: px(10);
        font-size: px(12);

        &.lg {
          font-size: px(14);
        }

        > strong {
          font-weight: 700;
        }
      }

      + .nav_tab {
        margin-top: px(12);
      }
    }

    .content_nav {
      margin: px(-10) px(-20) 0 px(-20);

      > ul {
        padding: px(10) px(0) px(30) px(20);

        > li {
          &:last-child {
            padding-right: px(20);
          }
        }
      }

      + section {
        margin-top: px(30);
        padding-top: px(30);
        border-top: px(1) solid #f2f2f2;
      }
    }

    section:first-child {
      padding-top: px(42);
      border-bottom: px(2) solid #f2f2f2;
    }

    .map_section {
      margin: 0 px(-20);
      padding: px(30) px(20);

      &:not(.last) {
        border-bottom: px(10) solid #f8f8f8;
      }

      &.last {
        padding-bottom: px(50);
      }
    }

    .sub_footer {
      margin: 0 px(-20);

      .tit {
        padding-bottom: px(3);
        border-bottom: px(1) solid #222;
        font-family: 'Noto Sans KR';
        font-size: px(14);
        font-weight: 500;

        > u {
          font-family: 'Noto Sans KR';
          text-decoration: none;
        }
      }

      .txt {
        font-family: 'Noto Sans KR';
        font-size: px(12);
        font-weight: 400;
        line-height: px(22);
      }
    }

    .nav_tab {
      margin-right: px(-20);
      padding-right: px(20);
    }
  }

  &_swiper {
    overflow: hidden;

    .swiper-wrapper {
      text-align: left;
    }

    .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: px(15);

      &-bullet {
        display: inline-block;
        width: px(8);
        height: px(8);
        background: #e3e3e3;
        border-radius: 50%;
        transition: background 0.3s;

        &-active {
          background: #222;
        }

        + span {
          margin-left: px(8);
        }
      }
    }
  }
}

.content_nav {
  > ul {
    display: flex;
    padding-bottom: px(30);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    > li {
      + li {
        margin-left: px(10);
      }
    }
  }

  &_link {
    display: block;
    width: px(128);
    padding: px(18) px(13) px(16);
    border-radius: px(10);
    box-shadow: 0 px(3) px(10) rgba(0, 0, 0, 0.2);
    color: #222;
    text-align: left;
    transition: padding 0.3s;

    &.bg_type1 {
      background: #3782ce;
      color: #fff;
    }

    &.bg_type2 {
      background: #15beb9;
      color: #fff;
    }

    > img {
      width: px(25);

      &.img_default + .img_simple {
        display: none;
      }
    }

    > strong {
      display: block;
      margin: px(14) 0 px(8);
      font-size: px(18);
      font-weight: 700;
    }

    > p {
      font-size: px(14);
    }
  }

  &.simple {
    > ul {
      > li {
        flex: none;

        > a,
        > button {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          padding: px(9) px(14);
          background: #f2f2f2;
          box-shadow: none;
          border-radius: px(100);
          white-space: nowrap;

          > strong {
            margin: 0 0 0 px(5);
          }

          &.bg_type1,
          &.bg_type2 {
            color: #222;
          }

          > p {
            display: none;
          }

          > img {
            width: px(23);
            height: px(23);

            &.img_default {
              display: none;
            }

            &.img_simple {
              display: inline;
            }
          }
        }
      }
    }
  }
}

.card_list {
  > li {
    position: relative;
    padding: px(15);
    background: #f8f8f8;
    border-radius: px(5);

    > strong {
      display: flex;
      align-items: center;
      font-size: px(16);
      font-weight: 700;
      color: #222;

      img {
        width: px(10);
        margin: 0 0 px(2) px(2);
      }

      + .text_pipe {
        margin-top: px(8);
      }
    }

    > p {
      margin: px(8) 0 px(5);
      font-size: px(14);
      color: #777;
    }

    + li {
      margin-top: px(8);
    }

    .link_ab {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .btn_location_kakao {
      margin-top: px(5);
    }
  }
}

.text_pipe {
  display: flex;

  &.lg {
    > li {
      font-size: px(14);
    }
  }

  > li {
    display: block;
    position: relative;
    font-size: px(12);
    color: #777;

    &.fw_bold {
      font-weight: 700;
    }

    + li {
      margin: 0 0 0 px(10);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: px(-5);
        width: px(1);
        height: px(10);
        background: #999;
        transform: translateY(-50%);
      }
    }
  }
}

.co_blue_green {
  color: #2bbcb0 !important;
}

.co_blue {
  color: #3a7bfb !important;
}

.text_label {
  display: inline-block;
  vertical-align: middle;

  &.type1 {
    padding: px(2) px(6);
    background: #ecf6ff;
    border-radius: px(20);
    font-family: 'segoe print';
    font-size: px(10);
    color: #3782ce;
  }
}

.text_mark {
  position: relative;
  padding-left: px(10);
  font-size: px(12);
  color: #999;

  > span {
    position: absolute;
    top: 0em;
    left: 0;
  }
}

.cs_tbl {
  padding: px(15) px(15) px(20);
  border: px(1) solid #e8e8e8;
  border-radius: px(15);

  &_header {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr;
    padding: px(10) px(14);
    background: #f8f8f8;
    border-radius: px(5);
    font-size: px(12);
    text-align: center;

    > strong {
      font-weight: 700;
    }
  }

  &_body {
    position: relative;

    .cs_tbl_more {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    > ol {
      max-height: px(200);
      padding-bottom: px(10);
      overflow: hidden;

      > li {
        display: grid;
        grid-template-columns: 1fr 3fr 2fr;
        align-items: center;
        padding: px(10) px(14);
        font-size: px(14);
        text-align: center;

        &:first-child {
          font-weight: 700;
        }
      }

      &.scroll_start {
        overflow: auto;
      }

      &.scroll_end {
        overflow: auto;
        padding-bottom: 0;

        + .cs_tbl_more {
          display: none;
        }
      }

      + .cs_tbl_more {
        width: 100%;
        height: px(50);
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 15%,
          rgb(255, 255, 255) 75%
        );
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          bottom: px(-12);
          left: 50%;
          width: px(28);
          height: px(28);
          background: url('../images/icon_arrow_bottom.svg') no-repeat 50% 50%/2.8rem;
          animation: scrollIntend 0.8s infinite alternate;
          z-index: 1;

          @keyframes scrollIntend {
            0% {
              transform: translate(-50%, -0%);
            }

            100% {
              transform: translate(-50%, -20%);
            }
          }
        }
      }
    }
  }

  + .text_mark {
    margin-top: px(15);
  }
}

.btn_location_kakao {
  display: inline-block;
  position: relative;
  padding: px(7) px(11) px(7) px(26);
  border: px(1) solid #e8e8e8;
  border-radius: px(100);
  background: #fff;
  font-size: px(12);
  color: #222;
  font-weight: 500;

  &:before {
    content: '';
    position: absolute;
    top: px(3);
    left: px(4);
    width: px(22);
    height: px(22);
    background: url('../images/icon_location_kakao.png') no-repeat 0 0/2.2rem;
  }
}

.ly_menu {
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
  }

  &_content {
    flex: 1;
    padding: px(20) px(20) 0;
  }

  .btn_wrap {
    .btn_regist {
      position: relative;
      padding: px(20);
      width: 100%;
      background: #f8f8f8;
      text-align: left;
      font-weight: 700;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: px(12);
        width: px(28);
        height: px(28);
        background: url('../images/icon_arrow_right.svg') no-repeat 50% 50%/0.8rem;
        transform: translateY(-50%);
      }
    }
  }
}

.ly_opinion {
  height: 100%;

  .header {
    padding: px(14) px(20);
    border-bottom: px(1) solid #efefef;

    .tit {
      padding: 0;
      font-size: px(16);
      font-weight: 700;
      text-align: left;
    }
  }

  .ly_content {
    padding: px(25) px(20);

    .item {
      + .item {
        margin-top: px(24);
      }

      .tit {
        display: block;
        margin-bottom: px(10);
        font-style: 'pretendard', 'Noto Sans KR';
        font-size: px(14);
        font-weight: 400;
      }

      .form {
        .ip_box {
          border-color: #efefef;

          .textarea {
            width: 100%;
            padding: px(16);
            resize: none;
            font-style: 'pretendard', 'Noto Sans KR';
            font-weight: 500;
            color: #222;

            &::placeholder {
              color: #cfcfcf;
            }
          }
        }
      }
    }
  }
}

.dl_menu {
  padding: 0 0 px(30);

  & + & {
    padding-top: px(30);
    border-top: px(1) solid #ebebeb;
  }

  dt {
    margin-bottom: px(15);
    font-family: 'pretendard', 'Noto Sans KR';
    font-size: px(14);
    font-weight: 300;
    color: #999;
  }

  dd {
    font-family: 'pretendard', 'Noto Sans KR';
    font-size: px(16);

    + dd {
      margin-top: px(10);
    }
  }

  a {
    color: inherit;
  }
}

.check_btn {
  display: inline-block;
  padding: px(8) px(12);
  border: px(1) solid #efefef;
  border-radius: px(5);
  font-size: px(14);
  font-weight: 300;
  color: #999;

  & + & {
    margin-left: px(10);
  }

  &.active {
    border-color: #222;
    color: #222;
    font-weight: 700;
  }
}

.btn_round {
  display: inline-block;
  padding: px(8) px(15);
  background: #fff;
  border: px(1) solid #ccc;
  border-radius: px(20);
  font-family: 'pretendard', 'Noto Sans KR';
  font-size: px(14);

  & + & {
    margin-left: px(5);
  }

  &.active {
    background: #222;
    border-color: #222;
    color: #fff;
    font-weight: 700;
  }

  &.close {
    width: px(22);
    height: px(22);
    padding: 0;
    border-radius: 50%;
    background: #ccc url('../images/icon_close_sm.svg') no-repeat 50% 50%/1rem;
  }
}

.info_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: px(40) px(20);
  margin-bottom: px(12);
  background: #f8f8f8;
  border-radius: px(5);
  text-align: center;

  > img {
    width: px(21);

    + p {
      margin-top: px(6);
    }
  }

  > p {
    font-size: px(14);
    font-weight: 300;
    color: #999;

    + .btn_round {
      margin-top: px(10);
    }
  }

  .btn_round {
    font-weight: 500;
    color: #222;

    &:not(.close) {
      min-width: px(95);
    }

    &.close {
      + p {
        color: #222;
      }
    }

    + p {
      margin-top: px(8);
    }
  }
}
