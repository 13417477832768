.promotion_wrap {
  &.hidden {
    display: none;
  }

  .interest_wrap {
    position: relative;
    min-height: 100vh;
    background: #24694c;

    &.type1 {
      padding-bottom: px(60);
    }

    .img_top_wrap {
      position: relative;

      .img_bean {
        position: absolute;
        width: px(62);
        bottom: -10vw;
        right: 4%;

        @media (min-width: 600px) {
          bottom: -6vw;
        }
      }
    }

    .noti_box {
      margin: 0 px(20) px(25);
      padding: px(22) px(22) px(30);
      background: #fff;
      border: px(2) solid #000;

      &_header {
        padding-bottom: px(15);
        margin-bottom: px(20);
        border-bottom: px(1) solid #000;
      }

      &_title {
        display: block;
        margin-bottom: px(6);
        font-family: 'S-CoreDream';
        font-weight: 900;
        font-size: px(18);
        text-align: center;
        line-height: px(24);

        + p {
          font-family: 'S-CoreDream';
          font-weight: 500;
          font-size: px(11);
          text-align: center;
          color: #666;
        }
      }

      &_bottom {
        display: flex;
        justify-content: center;
        margin-top: px(16);
        text-align: center;
      }
    }

    .btn_wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 px(55) px(30);

      button {
        display: inline-block;
        flex: 1;
        min-width: px(155);
        box-shadow: px(5) px(6) px(17) rgba(0, 0, 0, 0.34);
        border-radius: px(10);
        overflow: hidden;

        + button {
          margin-left: px(10);
        }
      }
    }

    .interest_input {
      width: 100%;
      height: px(35);
      padding: px(13) px(11);
      background: #ebebeb;
      border-radius: px(3);
      font-family: 'pretendard', 'Noto Sans KR';
      font-size: px(12);
      font-weight: 500;
      color: #222;

      &:disabled {
        opacity: 1;
      }

      &::placeholder {
        color: #999;
        font-weight: 300;
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    .btn_close {
      position: absolute;
      top: px(10);
      right: px(10);
      width: px(32);
      height: px(32);
      background: url('../images/promotion/interest/btn_close.png') no-repeat 0
        0/3.2rem;
    }

    .btn_bottom_fixed {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;

      .btn_secondary {
        flex: 3;
      }

      .btn_primary {
        flex: 4;
      }
    }

    .btn_secondary,
    .btn_primary {
      padding: px(21);
      font-family: 'S-CoreDream';
      font-size: px(14);
    }

    .btn_secondary {
      background: #222;
      color: #666;
      font-weight: 300;
    }

    .btn_primary {
      background: #ef3a09;
      color: #fff;
      font-weight: 700;
    }
  }
}

.ly_interest {
  &.show {
    display: block;

    &.ndim {
      left: auto;
    }
  }

  .wrap {
    border-radius: px(15);
    overflow: visible;
    .content {
      padding: px(36) px(20) px(20);

      &_bottom {
        display: flex;
        justify-content: center;
        margin-top: px(28);
        text-align: center;
      }
    }
  }

  &_title {
    display: block;
    margin-bottom: px(20);
    font-family: 'S-CoreDream';
    font-size: px(25);
    font-weight: 900;
    text-align: center;

    &.type1 {
      font-weight: 400;
      color: #666;

      + .p_strong {
        margin: px(36) 0 px(16);
      }
    }
  }

  .p_strong {
    font-family: 'S-CoreDream';
    font-size: px(20);
    font-weight: 900;
    text-align: center;
  }

  &_form {
    display: flex;
    align-items: center;

    .unit {
      display: inline-block;
      position: relative;
      width: px(10);
      margin: 0 px(6);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: px(8);
        height: px(1);
        background: #adadad;
        transform: translate(-50%, -50%);
      }
    }

    select,
    input {
      display: inline-block;
      border: px(2) solid #adadad;
      padding: px(10) px(10);
      font-family: 'SpoqaHanSans';
      font-size: px(16);
      font-weight: 700;

      &:focus {
        border-color: #222;
      }
    }

    select {
      width: px(72);
      background: url('../images/promotion/interest/icon_select.png') no-repeat
        calc(100% - 0.8rem) 50%/1rem;
    }

    input {
      flex: 1;
      width: 100%;
    }
  }

  .btn_area_wide {
    padding: 0 px(20) px(18);

    .skip {
      border-radius: px(5);
      font-family: 'S-CoreDream';
      font-size: px(14);
      font-weight: 700;
    }
  }

  .btn_close {
    position: absolute;
    top: px(-40);
    right: 0;
    width: px(32);
    height: px(32);
    background: url('../images/promotion/interest/btn_close.png') no-repeat 0 0/3.2rem;
  }
}

.label_check {
  display: inline-flex;
  min-width: px(16);
  min-height: px(16);

  input {
    position: absolute;

    + span {
      display: inline-block;
      position: relative;
      padding-left: px(20);
      font-family: 'pretendard', 'Noto Sans KR';
      font-weight: 300;
      font-size: px(12);
      color: #222;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: px(16);
        height: px(16);
        border: px(1) solid #adadad;
        border-radius: px(2);
      }
    }

    &:checked {
      + span {
        &:before {
          background: #222
            url('../images/promotion/interest/icon_checked_w.png') no-repeat 50%
            50%/1rem;
          border-color: #222;
        }
      }
    }
  }

  + button {
    border-bottom: px(1) solid #222;
    font-family: 'pretendard', 'Noto Sans KR';
    font-size: px(13);
    font-weight: 500;
  }

  &.type1 {
    input {
      + span {
        font-weight: 500;
        font-size: px(13);

        &:before {
          top: 0.2em;
          border-color: #222;
        }

        u {
          text-decoration: none;
          border-bottom: px(1) solid #222;
        }
      }
    }
  }

  &_wrap {
    display: flex;
    flex-wrap: wrap;
    margin: px(-14) 0 0 px(-14);
    padding: 0;

    & + & {
      margin-top: px(0);
    }

    .label_check {
      margin: px(14) 0 0 px(14);
    }

    + .interest_input {
      margin-top: px(10);
    }
  }
}

/* 챗봇 */
.ly_chat {
  position: fixed;
  bottom: 0%;
  right: 3%;
  width: 72px;
  height: 72px;
  z-index: 999;
  transform: translate(0%, -50%);
  .cont {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: none;

    img {
      width: 100%;
    }
  }
  .popclose {
    position: absolute;
    top: 3px;
    right: 6px;
    width: 18px;
    height: 18px;
    background: url('../images/icon_chat_close.png') center no-repeat;
    font-size: 0;
    background-size: 100% auto;
  }
}
